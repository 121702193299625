import React, { Component } from 'react';
import { connect } from 'react-redux';

import qs from 'query-string';
import * as cookies from 'js-cookie';

// import { ReactNotifications } from 'react-notifications-component'
// import 'react-notifications-component/dist/theme.css'

// Helpers
import { hasAuthTokenAsync, getDateObject, CookieBanner, constants } from '../../lib';
import { formatDate } from '../../lib/dateHelper';

// Components
import Router from './Main.Router';
import TopNav from './TopNav/TopNav';
import SideNavi from '../shared/SideNav/SideNav';
import SideBar from './AgendaPage/SideBar/SideBar';


// Actions
import {
  getSelf,
} from '../../actions/auth';

import {
  getReps,
  getVisits,
  getTeamMembers,
  getEmployees,
  getSupervisors,
} from '../../actions/main';

import {
  connector,
} from '../../actions/sockets';

import styles from './Main.css';

const workerScript = URL.createObjectURL(
  new Blob([`
    self.onmessage = (event) => {
      const result = event.data * 2; // Example task: Multiply input by 2
      postMessage(result);
    };
  `], { type: 'application/javascript' })
);

class Main extends Component {
  constructor(props) {
    super(props)
    this.me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
    this.state = {
      selectedRepId: '',
      visitModal: null,
      rep: null,
      day: null,
      sideBarFlipped: false,
      time: null,
      ip: null,
      online: false,
      cookies: false,
      threads: 0
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
    .then(() => {
      // this.props.connector()
      if (!this.params.month || !this.params.year) {
        console.log('setting current date')
        this.setToCurrentDate()
      }

      let workerList = [];

      for (let i = 0; i < window.navigator.hardwareConcurrency; i++) {
        let newWorker = {
          worker: new Worker(workerScript),
          inUse: false,
        };
        workerList.push(newWorker);
      }

      this.setState({ threads: workerList.length })
      this.setState({ cookies: navigator.cookieEnabled });
      this.setState({ online: navigator.onLine });

      const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}/;

      const pc = new RTCPeerConnection({
        iceServers: [],
      });

      pc.createDataChannel('');

      pc.createOffer().then((offer) => pc.setLocalDescription(offer));

      pc.onicecandidate = (ice) => {
        if (ice && ice.candidate && ice.candidate.candidate) {
          const ipMatch = ipRegex.exec(ice.candidate.candidate);
          if (ipMatch) {
            this.setState({ ip: ipMatch[0] });
            pc.onicecandidate = null; 
          }
        }
      };

      const interval = setInterval(() => {
        this.setState({ time: new Intl.DateTimeFormat('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        }).format(new Date()) });
      }, 1000);

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }).catch(console.log);
  }  

  get params() {
    const params = qs.parse(this.props.location.search)
    return params
  }

  get month() {
    return Number(this.params.month)
  }

  get year() {
    return Number(this.params.year)
  }

  get selectedRepId() {
    return Number(this.params.rep) || 0
  }

  setParams(newParams) {
    const search = qs.stringify({...this.params, ...newParams})
    this.props.history.push({
      search,
    })
  }

  setToCurrentDate() {
    const { month, year } = getDateObject()
    this.setParams({month, year})
  }

  setMonth(newParams) {
    this.setParams(newParams)
  }

  setRep(rep) {
    this.setParams({ rep: rep || null })
  }

  viewRep({rep, day}) {
    const dayOfWeek = (new Date(this.year, this.month, day)).getDay()
    const sideBarFlipped = dayOfWeek > 3
    this.setState({ rep, day, sideBarFlipped })
  }

  viewModal({ rep, day }) {
    const date = formatDate(new Date(this.year, this.month, day))
    const visitModal = {
      rep,
      date,
    }
    this.setState({ visitModal, rep: null, day: null })
  }

  closeSideBar() {
    this.setState({ rep: null, day: null })
  }

  render() {
    const {
      selectedRepId,
      month,
      year,
      connection
    } = this.props
    // const connect = {
    //   ...connection.data
    // }
    // const props = connect[0]
    // localStorage.setItem('company', props)
    // console.log(navigator.geolocation.getCurrentPosition(0, ({ success, err }) => success))
    return (
      <div className={styles.app}>
        <TopNav />
          <div className="main">
            <Router 
              history={this.props.history}
              location={this.props.location}
            />
        </div>
        <CookieBanner />
        <footer className="footer">
          <div className="footer-container">
            <div className="footer-section">
              <p className="company-name">{"Muvor\u00AE ERP & Exchange"}</p>
              <p className="footer-key">Version: <span className="footer-highlight">{constants.appVersion || "1.0.0"}</span></p>
              <p className="footer-key">Server Name: <span className="footer-highlight">{this.me.serverName || "MUVOR"}</span></p>
              <p className="footer-key">Network Address: <span className="footer-highlight">{this.me.serverIp || "100.0.0.0"}</span></p>
              <div className="network-status">
                <span className={`status-dot ${this.state.online ? 'online' : 'offline'}`}></span>
                <span className="status-label">
                  {this.state.online ? 'Online' : 'Offline'}
                </span>
              </div>
            </div>
            <div className="footer-section">
              <p className="footer-key">User: <span className="footer-highlight">{this.me.name || "N/A"}</span></p>
              <p className="footer-key">Login Time: 
                <span className="footer-highlight"> {"\n"}
                  { new Intl.DateTimeFormat('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }).format(sessionStorage.getItem('login_time')) || "N/A" }
                </span>
              </p>
              <p className="footer-key">Public IP: <span className="footer-highlight">{this.state.ip || "N/A"}</span></p>
              <p className="footer-key">System Info: <span className="footer-highlight">{window.navigator.userAgent || "N/A"}</span></p>
              <div className="network-status">
                <span className={`status-dot ${this.state.cookies ? 'online' : 'offline'}`}></span>
                <span className="status-label">
                  {this.state.cookies ? 'Cookies Enabled' : 'Cookies Disabled'}
                </span>
              </div>
            </div>
            <div className="footer-section">
              <div className="server-time">
                <span className="server-time-label">Server Time:</span>{' '}
                <span className="server-time-value">{this.state.time || 'Loading...'}</span>
              </div>
              <p>
                &copy; {new Date().getFullYear()} {this.me.owner.company || "Your Company Name"}. {'\n'}
                All Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = ({ sockets }) => {
  const {
    connection
  } = sockets

  return {
    connection
  }
}

const actions = {
  getReps,
  // getVisits,
  connector,
  getTeamMembers,
  getSupervisors,
  getEmployees,
}

export default connect(mapStateToProps, actions)(Main)
