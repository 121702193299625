import { communityTypes } from '../actions/types'

const {
  VERIFY_EMAIL,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,

  CREATE_GUEST,
  CREATE_GUEST_SUCCESS,
  CREATE_GUEST_FAILURE,

  GET_GUEST,
  GET_GUEST_SUCCESS,
  GET_GUEST_FAILURE,

  REMOVE_GUEST,
  REMOVE_GUEST_SUCCESS,
  REMOVE_GUEST_FAILURE,

  ADD_TO_CART,  
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,  

  GET_CART,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,

  REMOVE_FROM_CART,  
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAILURE, 

  GET_COMMUNITY,
  GET_COMMUNITY_FAILURE,
  GET_COMMUNITY_SUCCESS,

  GET_BLOCK,
  GET_BLOCK_SUCCESS,
  GET_BLOCK_FAILURE,

  GET_SCORE,
  GET_SCORE_SUCCESS,
  GET_SCORE_FAILURE,

  POST_TIMELINE,
  POST_TIMELINE_SUCCESS,
  POST_TIMELINE_FAILURE,

  GET_TIMELINE,
  GET_TIMELINE_FAILURE,
  GET_TIMELINE_SUCCESS,

  GET_OFFERINGS,
  GET_OFFERINGS_FAILURE,
  GET_OFFERINGS_SUCCESS,

  CREATE_OFFERING,
  CREATE_OFFERING_FAILURE,
  CREATE_OFFERING_SUCCESS,

  GET_COMMUNITY_MEMBER,
  GET_COMMUNITY_MEMBER_SUCCESS,
  GET_COMMUNITY_MEMBER_FAILURE,

  CREATE_COMMUNITY_MEMBER,
  CREATE_COMMUNITY_MEMBER_FAILURE,
  CREATE_COMMUNITY_MEMBER_SUCCESS,

  EXPORT_COMMUNITYS,
  EXPORT_SUCCESS,
  EXPORT_FAILURE,
  
  IMPORT_COMMUNITYS,
  IMPORT_SUCCESS,
  IMPORT_FAILURE,
  
  INIT_COMMUNITYS,
  INIT_SUCCESS,
  INIT_FAILURE,

  PATCH_COMMUNITY,
  PATCH_COMMUNITY_FAILURE,
  PATCH_COMMUNITY_SUCCESS,

  DELETE_COMMUNITY,
  DELETE_COMMUNITY_FAILURE,
  DELETE_COMMUNITY_SUCCESS,

  UPLOAD_FILE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,

  FILTER_COMMUNITYS,
  FILTER_SUCCESS,
  FILTER_FAILURE
} = communityTypes

const INITIAL_STATE = {
    communitys: [],
    communitysDisplay: [],

    block: [],
    score: [],
    timeline: [],
    offerings: [],

    init: [],

    barcode: '',
  
    checkedCommunitys: [],

    cart: [],
  
    photos: [],
    progress: 0,
  
    error: '',
    loading: false,
  }


export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
      case ADD_TO_CART:
        return {
          ...state,
          loading: true,
        }
      case ADD_TO_CART_SUCCESS:
        return {
          ...state,
          cart: payload,
          loading: false,
        }
      case ADD_TO_CART_FAILURE:
        return {
          ...state,
          cart: [],
          error: payload,
          loading: false,
        }
      case GET_CART:
        return {
          ...state,
          loading: true,
        }
      case GET_CART_SUCCESS:
        return {
          ...state,
          cart: payload,
          loading: false,
        }
      case GET_CART_FAILURE:
        return {
          ...state,
          cart: [],
          error: payload,
          loading: false,
        }
      case REMOVE_FROM_CART:
        return {
          ...state,
          loading: true,
        }
        case REMOVE_FROM_CART_SUCCESS:
        return {
          ...state,
          cart: payload,
          loading: false,
        }
      case REMOVE_FROM_CART_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case CREATE_GUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_GUEST_SUCCESS:
        return {
          ...state,
          guest: payload,
          loading: false,
        }
      case CREATE_GUEST_FAILURE:
        return {
          ...state,
          guest: [],
          error: payload,
          loading: false,
        }
      case GET_GUEST:
        return {
          ...state,
          loading: true,
        }
      case GET_GUEST_SUCCESS:
        return {
          ...state,
          guest: payload,
          loading: false,
        }
      case GET_GUEST_FAILURE:
        return {
          ...state,
          guest: [],
          error: payload,
          loading: false,
        }
      case REMOVE_GUEST:
        return {
          ...state,
          loading: true,
        }
        case REMOVE_GUEST_SUCCESS:
        return {
          ...state,
          cart: payload,
          loading: false,
        }
      case REMOVE_GUEST_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case GET_COMMUNITY:
        return {
          ...state,
          loading: true,
        }
      case GET_COMMUNITY_SUCCESS:
        return {
          ...state,
          communitysDisplay: payload,
          loading: false,
        }
      case GET_COMMUNITY_FAILURE:
        return {
          ...state,
          communitys: [],
          communitysDisplay: [],
          error: payload,
          loading: false,
        }
      case GET_BLOCK:
        return {
          ...state,
          loading: true,
        }
      case GET_BLOCK_SUCCESS:
        return {
          ...state,
          block: payload,
          loading: false,
        }
      case GET_BLOCK_FAILURE:
        return {
          ...state,
          block: [],
          error: payload,
          loading: false,
        }      
      case GET_SCORE:
        return {
          ...state,
          loading: true,
        }
      case GET_SCORE_SUCCESS:
        return {
          ...state,
          score: payload,
          loading: false,
        }
      case GET_SCORE_FAILURE:
        return {
          ...state,
          score: [],
          error: payload,
          loading: false,
        }   
      case POST_TIMELINE:
        return {
          ...state,
          loading: true,
        }
      case POST_TIMELINE_SUCCESS:
        return {
          ...state,
          timeline: payload,
          loading: false,
        }
      case POST_TIMELINE_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }   
      case GET_TIMELINE:
        return {
          ...state,
          loading: true,
        }
      case GET_TIMELINE_SUCCESS:
        return {
          ...state,
          timeline: payload,
          loading: false,
        }
      case GET_TIMELINE_FAILURE:
        return {
          ...state,
          timeline: [],
          error: payload,
          loading: false,
        }
      case GET_OFFERINGS:
        return {
          ...state,
          loading: true,
        }
      case GET_OFFERINGS_SUCCESS:
        return {
          ...state,
          offerings: payload,
          loading: false,
        }
      case GET_OFFERINGS_FAILURE:
        return {
          ...state,
          offerings: [],
          error: payload,
          loading: false,
        }
      case CREATE_OFFERING:
        return {
          ...state,
          loading: true,
        }
      case CREATE_OFFERING_SUCCESS:
        return {
          ...state,
          offerings: payload,
          loading: false,
        }
      case CREATE_OFFERING_FAILURE:
        return {
          ...state,
          offerings: [],
          error: payload,
          loading: false,
        }
      case GET_COMMUNITY_MEMBER:
        return {
          ...state,
          loading: true,
        }
      case GET_COMMUNITY_MEMBER_SUCCESS:
        return {
          ...state,
          communitysDisplay: payload,
          loading: false,
        }
      case GET_COMMUNITY_MEMBER_FAILURE:
        return {
          ...state,
          communitys: [],
          communitysDisplay: [],
          error: payload,
          loading: false,
        }
      case CREATE_COMMUNITY_MEMBER:
        return {
          ...state,
          loading: true,
        }
      case CREATE_COMMUNITY_MEMBER_SUCCESS:
        return {
          ...state,
          communitys: payload,
          communitysDisplay: payload,
          loading: false,
        }
      case CREATE_COMMUNITY_MEMBER_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
        case IMPORT_COMMUNITYS:
        return {
          ...state,
          loading: true,
        }
      case IMPORT_SUCCESS:
        return {
          ...state,
          imports: payload,
          importsDisplay: payload,
          loading: false,
        }
      case IMPORT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
        
      case INIT_COMMUNITYS:
        return {
          ...state,
          loading: true,
        }
      case INIT_SUCCESS:
        return {
          ...state,
          init: payload,
          loading: false,
        }
      case INIT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }

        case EXPORT_COMMUNITYS:
        return {
          ...state,
          loading: true,
        }
      case EXPORT_SUCCESS:
        return {
          ...state,
          exports: payload,
          exportsDisplay: payload,
          loading: false,
        }
      case EXPORT_FAILURE:
        return {
          ...state,
          exports: [],
          exportsDisplay: [],
          error: payload,
          loading: false,
        }

      case PATCH_COMMUNITY:
        return {
          ...state,
          loading: true,
        }

      case PATCH_COMMUNITY_SUCCESS:
        return {
          ...state,
          communitys: payload,
          communitysDisplay: payload,
          loading: false,
        }
      case PATCH_COMMUNITY_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case VERIFY_EMAIL:
        return {
          ...state,
          loading: true,
        }
        case VERIFY_SUCCESS:
        return {
          ...state,
          verify: payload,
          loading: false,
        }
      case VERIFY_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
  
      case DELETE_COMMUNITY:
        return {
          ...state,
          loading: true,
        }
      case DELETE_COMMUNITY_SUCCESS:
        return {
          ...state,
          communitys: payload,
          communitysDisplay: payload,
          loading: false,
        }
      case DELETE_COMMUNITY_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case FILTER_COMMUNITYS:  
        return {
          ...state,
          communitysDisplay: payload,
          barcode: payload,
          loading: false
        }

      case FILTER_SUCCESS:
        return {
          ...state,
          barcode: payload,
          communitysDisplay: payload,
          loading: false,
        }

    case FILTER_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }

      case UPLOAD_FILE:
        return {
          ...state,
          progress: 0,
          loading: true,
        }
      case UPLOAD_FILE_PROGRESS:
        return {
          ...state,
          progress: payload,
        }
      case UPLOAD_FILE_SUCCESS:
        return {
          ...state,
          progress: 0,
          photos: [...state.photos, payload],
          loading: false,
        }
      case UPLOAD_FILE_FAILURE:
        return {
          ...state,
          progress: 0,
          loading: false,
        }
        default:
        return state     
    }
}