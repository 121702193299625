import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
  timeStatusOptions,
  formatCurrencyOptions,
} from '../../../../../../../lib'

// Components
import {
  Header,
  Button,
  Selector,
  SummaryItem,
} from '../../../../../../common'

import {
  TimesTable,
  SummaryRow,
} from '../../../../../../shared'

import {
  filterExpensesByStatus,
} from '../../../../../../../actions/expenses'


import {
  bulkSubmitTimes,
  start,
  stop
} from '../../../../../../../actions/timesheets'

import { getProjects } from '../../../../../../../actions/projects';
import { getTeams } from '../../../../../../../actions/team'
import TimerModal from '../../../../../../shared/TimerModal/timerModal';

import * as cookies from 'js-cookie';

import styles from './DashboardTab.css'

class DashboardTab extends Component {
  constructor(props) {
    super(props)
    const bulkAction = timeStatusOptions[0].value
    this.state = {
      timerModal: null,
      statusFilter: '',
      bulkAction,
    }
    // this.timesheet = this.props.getSheetById(window.location.pathname.split('/')[3])
    this.me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined;
    this.isme = this.me && this.member && (this.me.company === this.member.mvrxt || this.me._id === this.member._id)
  }

  componentDidMount() {
    this.props.getProjects()
    this.props.getTeams()
  }

  filterExpensesByStatus(statusFilter) {
    this.setState({ statusFilter })
    this.props.filterExpensesByStatus(statusFilter)
  }

  bulkUpdate(status) {
    const { times } = this.props; 
    const t = !!times && times.times.reduce((accumulator, currentValue) => accumulator + currentValue.time, 0)
    const totalHours = t / (1000 * 60 * 60); // Convert milliseconds to hours
    const formattedHours = totalHours.toFixed(5); // Round to 5 decimal places (ms)
    const confirmed = window.confirm(`Submit ${formattedHours} hours to ${times.supervisors.map(s => `${s.name} `)}?`)
    if (confirmed) {
      const update = {
        status,
        totalHours,
        times
      }
      this.props.bulkSubmitTimes(update)
    }
  }

  onClick(e) {
    e.preventDefault();
    // const { sheet, user } = this.props
    // const data = {
    //   user: user.id,
    //   company: user.company,
    //   role: user.role,
    //   sheet: sheet._id,
    //   date: Date.now()
    // } 
    this.setState({ timerModal: {} })
    // this.props.start(data);
  }

  render() {
    const {
      bulkAction,
      statusFilter,
    } = this.state

    const statusOptions = [
      {
        value: '',
        display: 'All',
      },
      ...timeStatusOptions,
    ]

    const {
      stop,
      user,
      sheet,
      times,
      teams,
      projects
    } = this.props

    // const expensesTotal = expensesDisplay
    //   .map(el => el.amount)
    //   .reduce((acc, curr) => acc + curr, 0)
    //   .toLocaleString(...formatCurrencyOptions)

    if (!!this.state.timerModal) {
      return ( 
        <TimerModal
          isme={this.isme}
          me={this.me}
          stop={stop}
          projects={projects.concat(teams)}
          content={this.state.timerModal}
          onClickAway={() => this.setState({ timerModal: null })}
        />
      )
    }
    return !!times && <div className={styles.dashboardTab}>
      <SummaryRow times={times} user={user} />
      <br />
      <div className="expenses">
        <div className="actions">
          <div>
            Status
            <div className="status">
              {times.approvalStatus}
            </div>
          </div>
          <div>
            <Selector
              label="Bulk Action"
              options={timeStatusOptions}
              selected={bulkAction}
              onSelect={bulkAction => this.setState({ bulkAction })}
            />
            <Button
              inactive={!times}
              title="APPLY"
              onClick={() => this.bulkUpdate(bulkAction)}
            />
          </div>
          <Button
              title="NEW TIME"
              onClick={this.onClick.bind(this)}
            />
        </div>

        <TimesTable projects={projects} times={times}/>
      </div>
    </div>
  }
}

const mapStateToProps = ({ team, expense, timesheets, projects }) => {
  const {
    user,
    teams
  } = team

  const {
    checkedExpenses,
    expensesDisplay,
  } = expense

  const {
    timesheetDisplay
  } = timesheets

  const {
    projectsDisplay
  } = projects

  return {
    user,
    teams,
    expensesDisplay,
    checkedExpenses,
    timesheetDisplay,
    projects: projectsDisplay
  }
}

const actions = {
  filterExpensesByStatus,
  bulkSubmitTimes,
  getProjects,
  getTeams,
  start,
  stop
}

export default connect(mapStateToProps, actions)(DashboardTab);
