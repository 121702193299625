const timeStatusOptions = [
    {
      value: 'submit',
      display: 'Submit',
    },
    {
      value: 'request',
      display: 'Request',
    },
    {
      value: 'delete',
      display: 'Delete',
    },
  ]
  
  export default timeStatusOptions
  