import React, { Component } from 'react';
import { connect } from 'react-redux'

// Components
import {
  Header,
  Button,
  Selector,
  SummaryItem,
  Table,
  Span,
} from '../../../../../common'

import styles from './TimelineTab.css'
import TimelineModal from './TimelineModal/TimelineModal';

class TimelineTab extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      timelineModal: null
    }
  }

  componentDidMount() {
  }

  renderTableHead() {
    return (
      <thead className={styles.head}>
        <tr>
          <th>
            Timeline
          </th>
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const {
      timeline
    } = this.props

    if (this.props.me) {
      if (!timeline.announcements.length > 0) {
        return (
          <tbody>
            <tr>
              <td colSpan="9" className="empty">
                <Button times onClick={() => this.setState({ timelineModal: {} })}>
                  Add to timeline
                </Button>
              </td>
            </tr>
          </tbody>
        )
      } else {
        return (
          <tbody>
              {timeline.announcements.map(this.renderTableRow.bind(this))}
              <Button times onClick={() => this.setState({ timelineModal: {} })}>
                Add to timeline
              </Button>
          </tbody>
        )
      }
    } else if (!this.props.me) {
      if (!timeline.announcements.length > 0) {
        return (
          <tbody>
            <tr>
              <td className="empty">
                Hasn't Muved!
              </td>
            </tr>
          </tbody>
        )
      } else {
        return (
          <tbody>
            {timeline.announcements.map(this.renderTableRow.bind(this))}
          </tbody>
        )
      }
    }
  }

  renderTableRow(post) {    
    return (
      <tr className={styles.map}>
        <td>
            {post}
        </td>

        {/* <td className={styles.detailsCell}>
          <Button
            title="COMMENTS"
            link={`/employees/${employee._id}`}
            onClick={() => this.props.setEmployee(employee)}
          />
        </td> */}
      </tr>
    )
  }

  render() { 
    if (!!this.state.timelineModal) {
      return (
        <TimelineModal
          coin={this.props.coin}
          content={this.state.timelineModal}
          onClickAway={() => this.setState({ timelineModal: null })}
        />
      )
    }
    return ( 
      <Table className={styles.app}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    );
  }
}

// ?flip
const actions = {}
const mapStateToProps = () => {}
export default connect(mapStateToProps, actions)(TimelineTab);
