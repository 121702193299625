import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

// 3rd Party Components
// import FontAwesome from 'react-fontawesome'

// Components
import {
  Tr,
  Header,
  ActionBox,
  Button,
  SearchBar,
  Selector,
  Table,
} from '../../../../common'

// Actions
import {
  getCommunity,
  getBlock,
  getScore,
  setMember
} from '../../../../../actions/community'

import styles from './CommunityView.css'

class CommunityView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchType: '',
      search: '',
    }
  }

  componentDidMount() {
    this.props.getCommunity()
    // this.props.community && this.props.getScore(this.props.community.game)
    // this.props.community && this.props.getBlock(this.props.community.mvrxt)
  }

  filterUsers(search) {
    const { searchType } = this.state
    this.setState({ search, searchType })
  }

  // viewMember(user) {
  //   console.log(user)
  //   this.props.setMember(user)
  // }

  renderTableHead() {
    return (
      <thead className="thead">
        <tr>
          <th className="names">
            Logo
          </th>
          <th className="names">
            Name
          </th>
          <th className="wallet">
            Address
          </th>
          <th className="wallet">
            Block
          </th>
          <th className="scores">
            Offerings
          </th>
          <th className="scores">
            Score
          </th>
          <th className="scores">
            Achievements
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const { community } = this.props;
    const { searchType, search } = this.state;

    return (
      <tbody className="tbody">
        {community.length > 0 
        && community
        // .filter(c => c.mvrxt.some(t => "_id" in t))
        // .filter(c => c.portfolio.some(p => "_id" in p))
        .filter(c => searchType === 'name' ? c.name.startsWith(search) 
        : searchType === 'address' ? c.wallet.includes(search)
        // : searchType === 'block' 
        // ? c.mvrxt[0].codec.startsWith(search)
        : c.name.startsWith(search) || c.wallet.includes(search) || c.codec.startsWith(search))
        .map(this.renderTableRow.bind(this))}
      </tbody>
    )
  }

  renderTableRow(member) {
    const link = `/community/${member._id}`
    const date = moment(member.created_at).format('MM/DD/YYYY')
    return (
      <Tr
        key={member._id}
        onClick={() => this.props.setMember(member)}>
        <td className="names">
          <img src={member.logo} />
        </td>
        <td className="names">
          {member.name}
        </td>
        <td className="wallet"> 
          {member.wallet}
        </td>
        <td className="scores">
          {member.codec}
        </td>
        <td className="scores">
          {member.subs[0].offerings.length}
        </td>
        <td className="scores">
          {member.subs[0].receipts.length}
        </td>  
        <td className="scores">
          {member.subs[0].achievements.length}
        </td>  
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            onClick={() => window.location.pathname = link }
            // inactive
          />
        </td>
      </Tr>
    )
  }

  renderTable() {
    return (
      <Table className={styles.table} hoverable borderless>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  get placeholderText() {
    switch (this.state.searchType) {
      case 'name':
        return 'Search By Username...'
      case 'address':
        return 'Search By Wallet Address...'
      case 'block':
        return 'Search By Block...'
      default:
        return 'Start typing...'
    }
  }

  get searchOptions() {
    return [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'address',
        display: 'Address',
      },
      {
        value: 'block',
        display: 'Block',
      },
    ]
  }


  render() {
    const { searchType } = this.state

    return (
      <div className={styles.app}>
        <div className={styles.body}>
          <ActionBox>
            <div className={styles.main}>
              <SearchBar
                options={this.searchOptions}
                selected={searchType}
                onSelect={searchType => this.setState({ searchType })}
                label="Search"
                placeholder={this.placeholderText}
                value={this.state.search}
                onChange={search => this.setState({ search })}
              />
            </div>
            <div className="action">
              <Button
                link='/community/add'
                title="Join the Community!"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ main, community }) => {
  const {
    adminsDisplay,
  } = main
  const {
    communitysDisplay,
  } = community

  return {
    users: adminsDisplay,
    community: communitysDisplay
  }
}

const actions = {
  getCommunity,
  getBlock,
  getScore,
  setMember
}

export default connect(mapStateToProps, actions)(CommunityView);
