// Cookies
import * as cookies from 'js-cookie'

import { api } from '../config'

export default {
    post(data) {
        const url = '/communitys'
        return api.post(url, data)
    },
    getCommunity() {
        const u =  sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : ''
        const id = u.company
        const url = `/${id}/commnuitys`
        return api.get(url)
    },
    getBlock() {
        const u = sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : ''
        const id = u._id
        const url = `/timeline/${id}`
        return api.get(url)
    },
    getScore() {
        const u = sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : ''
        const id = u._id
        const url = `/timeline/${id}`
        return api.get(url)
    },
    getTimeline() {
        const u = sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : ''
        const id = u._id
        const url = `/timeline/${id}`
        return api.get(url)
    },
    getOfferings() {
        const u = sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : ''
        const id = u._id
        const url = `/offerings/${id}`
        return api.get(url)
    },
    createOffering(data) {
        const u = sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : ''
        const id = u._id
        const url = `/offerings/${id}`
        return api.post(url, data)
    },
    addTo (data) {
        const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
        const id = u._id
        const url = `/cart/${id}`
        return api.put(url, data)
    },
    getCart () {
        const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
        const id = u.cart
        const url = `/cart/${id}`
        return api.get(url)
    },
    removeFromCart (data) {
        const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
        const id = u.cart
        const url = `/${id}/cart`
        return api.put(url, data)
    },
    createGuest () {
        const url = '/guest'
        return api.post(url)
    },
    getGuest (data) {
        const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
        const id = u.account
        const url = `/guest/${id}`
        return api.get(url, data)
    },
    removeGuest (data) {
        const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
        const id = u.account
        const url = `/${id}/guest`
        return api.put(url, data)
    },
    getById(id) {
        const { _id, account, mvrxt } = id
        const url = `/communitys/${_id}/${account}/${mvrxt}`
        return api.get(url)
    },

    patchById(id, update) {
        const url = `/communitys/${id}`
        console.log(update)
        return api.patch(url, update)
    },

    deleteById(id) {
        const url = `/communitys/${id}`
        return api.delete(url, id)
    }
}