import { employeesTypes } from '../actions/types'

const {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_SUCCESS,
  SET_EMPLOYEE,
  GET_EMPLOYEE,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_SUCCESS,
  PATCH_EMPLOYEE,
  PATCH_EMPLOYEE_FAILURE,
  PATCH_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = employeesTypes

const INITIAL_STATE = {
  employeesDisplay: [],
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {
    case CREATE_EMPLOYEE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_EMPLOYEE:
      return {
        ...state,
        employeesDisplay: payload,
      }
    case GET_EMPLOYEE:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeesDisplay: payload,
        loading: false,
      }
    case GET_EMPLOYEE_FAILURE:
      return {
        ...state,
        employeesDisplay: null,
        error: payload,
        loading: false,
      }
    case PATCH_EMPLOYEE:
      return {
        ...state,
        loading: true,
      }
    case PATCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeesDisplay: payload,
        loading: false,
      }
    case PATCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case DELETE_EMPLOYEE:
      return {
        ...state,
        loading: true,
      }
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeesDisplay: payload,
        loading: false,
      }
    case DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_NOTE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        employeesDisplay: payload,
        loading: false,
      }
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
