
const stream = (socket) => {

  //set socketId
  let socketId = socket.io.engine.id;

  socket.on('subscribe', (data) => {
    // subscribe/join a room
    socket.join(data.room);
    socket.join(data.socketId);

    // Inform other members in the room of new user's arrival
    if (socket.adapter.rooms[data.room].length > 1) {
      socket.to(data.room).emit('new user', { socketId: data.socketId });
    }
  });
  
  socket.emit('subscribe', {
    // room,
    socketId
  });

  // socket.on('message', message => {
  //   storeDispatch(
  //     connectionOperations.receive(message)
  //   );
  // });

  socket.on('newUserStart', (data) => {
    socket.to(data.to).emit('newUserStart', { sender: data.sender });
  });
  
  // socket.on('newUserStart', (data) => {
  //   pc.push(data.sender);
  //   this.init(false, data.sender);
  // });


  socket.on('sdp', (data) => {
    socket.to(data.to).emit('sdp', { description: data.description, sender: data.sender });
  });


  socket.on('icecandidates', (data) => {
    console.log('ice data: ', data)
    socket.to(data.to).emit('ice candidates', { candidate: data.candidate, sender: data.sender });
  });


  socket.on('chat', (data) => {
    socket.to(data.room).emit('chat', { sender: data.sender, msg: data.msg });
  });

  // socket.on('chat', (data) => {
  //   socket.addChat(data, 'remote');
  // });

  // socket.on('new user', (data) => {
  //     socket.emit('newUserStart', { to: data.socketId, sender: socketId });
  //     this.pc.push(data.socketId);
  //     this.init(true, data.socketId);
  // });
};

module.exports = stream;
