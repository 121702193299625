import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

import {
  formatCurrencyOptions,
  expenseStatusOptions,
  expenseTypeOptions,
} from '../../../../lib'

// Components
import {
  Span,
  Icon,
  Input,
  Selector,
} from '../../../common'

import {
  checkExpense,
  patchExpenseById,
  deleteExpenseById,
  patchExpenseStatus,
} from '../../../../actions/expenses'

import styles from './TimesRow.css'

class TimesRow extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  get initialState() {
    const {
      note,
      amount,
      type,
    } = this.props.times

    return {
      editing: false,
      note,
      amount,
      type,
    }
  }

  get formIsInvalid() {
    const {
      amount,
      type,
    } = this.state

    return !(
      amount
      && type
    )
  }

  setEditState(editing) {
    this.setState({
      ...this.initialState,
      editing,
    })
  }

  save() {
    if (this.formIsInvalid) {
      return
    }

    const {
      type,
      note,
    } = this.state

    const update = {
      amount: Number(this.state.amount),
      type,
      note,
    }

    const { id } = this.props.expense

    this.props.patchExpenseById(id, update)
    this.setState({ editing: false })
  }

  delete() {
    const message = `Are you sure you want to delete this receipt?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }

    const { id } = this.props.expense

    this.props.deleteExpenseById(id)
  }

  renderIcons() {
    const { editing } = this.state
    const { isadmin } = this.props
    if (isadmin) {
      return null
    } else if (editing) {
      return (
        <td className="icons">
          <Icon
            small
            cancel
            onClick={() => this.setEditState(false)}
          />
          <Icon
            small
            save
            onClick={() => this.save()}
          />
        </td>
      )
    } else {
      return (
        <td className="icons">
          <Icon
            small
            edit
            onClick={() => this.setEditState(true)}
          />
          <Icon
            small
            cancel
            onClick={() => this.delete()}
          />
        </td>
      )
    }
  }

  setExpenseStatus(status) {
    const { expense } = this.props
    const { userId } = expense
    const expenseId = expense.id
    this.props.patchExpenseStatus({ userId, expenseId, status })
  }

  renderPhotoLink(photo, index) {
    return (
      <a
        className="photo-link"
        href={photo.url}
        target="_blank"
        rel="noopener noreferrer"
        key={photo.id}
      >
        Photo {index + 1}
      </a>
    )
  }

  render() {
    const {
      times,
      isadmin,
      checked,
      projects
    } = this.props

    const {
      date: created_at,
      description,
      status,
    } = times

    function formatTime(milliseconds) {
      let ms = Math.floor(milliseconds % 1000); // Extract milliseconds
      let seconds = Math.floor((milliseconds / 1000) % 60); // Convert to seconds
      let minutes = Math.floor((milliseconds / (1000 * 60)) % 60); // Convert to minutes
      let hours = Math.floor(milliseconds / (1000 * 60 * 60)); // Convert to hours
    
      // Ensure double digits for consistency
      let formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0'),
        ms.toString().padStart(2, '0')
      ].join(':');
    
      return formattedTime;
    }

    const date = moment(created_at).format('MM/DD/YYYY')
    
    // let project = typeof projects != undefined && projects.filter(p => p._id === times.project)

    const {
      editing,
      amount,
      type,
      note,
    } = this.state
    return (
      <tr className={styles.row}>
        {isadmin && (
          <td className="checkbox">
            <Input
              type="checkbox"
              className="checkbox"
              value={checked}
              onChange={() => this.props.checkExpense(times)}
            />
          </td>
        )}
        <td>
          <Span icon="calendar" >
            {date}
          </Span>
        </td>
        <td>
          {editing ? (
            <Selector
              options={expenseTypeOptions}
              selected={type}
              onSelect={type => this.setState({ type })}
            />
          ) : (
            times.project.name
          )}
        </td>
        <td>
          <Span
            editing={editing}
            value={amount}
            type="number"
            onChange={amount => this.setState({ amount })}
          >
            {formatTime(times.time)}
          </Span>
        </td>
        <td>
          <Span
              editing={editing}
              value={description}
              placeholder="Description"
              onChange={description => this.setState({ description })}
            >
            {times.description}
          </Span>
        </td>

        {this.renderIcons()}
      </tr>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    isadmin,
  } = auth

  return {
    isadmin,
  }
}

const actions = {
  checkExpense,
  patchExpenseById,
  deleteExpenseById,
  patchExpenseStatus,
}

export default connect(mapStateToProps, actions)(TimesRow)
