import Axios from 'axios';

// Router
import { push } from 'react-router-redux'

// Services
import { timesheets } from '../services'

import { timesheetTypes } from './types'

import * as cookies from 'js-cookie';

const {
  CREATE_TIMESHEET,
  CREATE_TIMESHEET_SUCCESS,
  CREATE_TIMESHEET_FAILURE,
  GET_TIMESHEET,
  GET_TIMESHEET_SUCCESS,
  GET_TIMESHEET_FAILURE,
  GET_SHEET_BY_ID,
  GET_SHEET_BY_ID_SUCCESS,
  GET_SHEET_BY_ID_FAILURE,
  GET_ADMIN_SHEET,
  GET_ADMIN_SHEET_SUCCESS,
  GET_ADMIN_SHEET_FAILURE,
  LIST,
  GET,
  ASSIGN_TAG_SUCCESS,
  ASSIGN_TAG_FAILURE,
  SET_TIMESHEET,
  BULK_SUBMIT_TIMES,
  BULK_SUBMIT_TIMES_SUCCESS,
  BULK_SUBMIT_TIMES_FAILURE,
  TIME_ENTRY_INPUT_PULL,
  TIME_ENTRY_INPUT_PULL_SUCCESS,
  TIME_ENTRY_INPUT_PULL_FAILURE,
  TIME_ENTRY_INPUT_START,
  TIME_ENTRY_INPUT_STOP,
  TIME_ENTRY_INPUT_STOP_SUCCESS,
  TIME_ENTRY_INPUT_STOP_FAILURE,
  TIME_ENTRY_INPUT_REMOVE,
  TIME_ENTRY_INPUT_REMOVE_SUCCESS,
  TIME_ENTRY_INPUT_REMOVE_FAILURE,
  TIME_ENTRY_INPUT_ASSIGN_TAG,
  TIME_ENTRIES_ASSIGN_TAG,
  TIME_ENTRIES_ASSIGN_TAG_SUCCESS,
  TIME_ENTRIES_ASSIGN_TAG_FAILURE,
  TIME_ENTRIES_ASSIGN_STATUS,
  TIME_ENTRIES_ASSIGN_STATUS_SUCCESS,
  TIME_ENTRIES_ASSIGN_STATUS_FAILURE,
  TIME_ENTRY_INPUT_CHANGE_TEXT,
  TIME_ENTRY_INPUT_CHANGE_TEXT_SUCCESS,
  TIME_ENTRY_INPUT_CHANGE_TEXT_FAILURE,
  TIME_ENTRY_INPUT_CHANGE_START_TIME,
  TIME_ENTRY_INPUT_CHANGE_START_TIME_SUCCESS,
  TIME_ENTRY_INPUT_CHANGE_START_TIME_FAILURE
} = timesheetTypes


const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;
const apiUrl = '/api/users/all/timesheets';

const url = timesheetId => {
  let url = apiUrl;
  if (timesheetId) {
    url += `/${timesheetId}`;
  }

  return url;
};

export const listTimesheets = () => {
  return dispatch => {
    return Axios.get(url())
      .then(response => {
        dispatch(list(response.data));
        console.log('Timesheets retrieved.');
      })
      .catch(error => {
        console.log('Error attempting to retrieve timesheets.');
      });
  };
};

export const getTimesheet = () => dispatch => {
  dispatch({
    type: GET_TIMESHEET,
  })
  timesheets.getAll(cid,uid)
    .then(res => onGetTimesheetSuccess({dispatch, res}))
    .catch(err => onGetTimesheetFailure({dispatch, err}))
}

function onGetTimesheetSuccess({dispatch, res}) {
  console.log('getTime success');
  dispatch({
    type: GET_TIMESHEET_SUCCESS,
    payload: res.data,
  })
}

function onGetTimesheetFailure({dispatch, err}) {
  console.log('getTime failure', err);
  const payload = err.message || 'GetTime Failed'
  window.alert(payload)
  dispatch({
    type: GET_TIMESHEET_FAILURE,
    payload,
  })
  dispatch(push('/timesheets'))
}

export const getSheetById = id => dispatch => {
  dispatch({
    type: GET_SHEET_BY_ID,
  })
  timesheets.getById(id)
    .then(res => onGetSheetByIdSuccess({dispatch, res}))
    .catch(err => onGetSheetByIdFailure({dispatch, err}))
}

function onGetSheetByIdSuccess({dispatch, res}) {
  console.log('getSheet success');
  dispatch({
    type: GET_SHEET_BY_ID_SUCCESS,
    payload: res.data,
  })
}

function onGetSheetByIdFailure({dispatch, err}) {
  console.log('getSheet failure', err);
  const payload = err.message || 'GetSheet Failed'
  window.alert(payload)
  dispatch({
    type: GET_SHEET_BY_ID_FAILURE,
    payload,
  })
  dispatch(push('/timesheets'))
}

export const getAdminSheets = cid => dispatch => {
  dispatch({
    type: GET_ADMIN_SHEET,
  })
  timesheets.getAdmin(cid)
    .then(res => onGetAdminSheetSuccess({dispatch, res}))
    .catch(err => onGetAdminSheetFailure({dispatch, err}))
}

function onGetAdminSheetSuccess({dispatch, res}) {
  console.log('getSheets success');
  dispatch({
    type: GET_ADMIN_SHEET_SUCCESS,
    payload: res.data,
  })
}

function onGetAdminSheetFailure({dispatch, err}) {
  console.log('getSheets failure', err);
  const payload = err.message || 'GetSheets Failed'
  window.alert(payload)
  dispatch({
    type: GET_ADMIN_SHEET_FAILURE,
    payload,
  })
  dispatch(push('/timesheets'))
}

export const updateTimesheet = timesheet => {
  return dispatch => {
    return Axios.put(url(timesheet._id), timesheet)
      .then(response => {
        dispatch(get(response.data));
        console.log(`Timesheet : ${timesheet._id}, updated.`);
        return true;
      })
      .catch(err => {
        console.log('There was an error updating timesheet.');
      });
  };
};

export const removeTimesheet = timesheet => {
  return dispatch => {
    timesheet.deleted = true;

    return Axios.put(url(timesheet._id), timesheet)
      .then(response => {
        dispatch(get(response.data));
        console.log(`Timesheet : ${response.data._id}, was deleted.`);
        return true;
      })
      .catch(err => {
        console.log('Error attempting to delete timesheet.');
      });
  };
};

export const restoreTimesheet = timesheet => {
  return dispatch => {
    timesheet.deleted = false;

    return Axios.put(url(timesheet._id), timesheet)
      .then(response => {
        dispatch(get(response.data));
        console.log(`Timesheet : ${response.data._id}, was restored.`);
        return true;
      })
      .catch(err => {
        console.log('Error attempting to restore timesheet.');
      });
  };
};

export const createTimesheet = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_TIMESHEET,
  })
  timesheets.post(data)
    .then(res => onCreateTimesheetSuccess({dispatch, getState, res}))
    .catch(err => onCreateTimesheetFailure({dispatch, err}))
}

function onCreateTimesheetSuccess({dispatch, getState, res}) {
  console.log('createTimesheet success');
  const { timesheets } = getState()
  dispatch({
    type: CREATE_TIMESHEET_SUCCESS,
    payload: [res, timesheets]
  })
  dispatch(push(`/reports/${res.data._id}`))
}

function onCreateTimesheetFailure({dispatch, err}) {
  console.log('createTimesheet failure', err);
  const payload = err.message || 'CreateTimesheet Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_TIMESHEET_FAILURE,
    payload,
  })
}

export const list = timesheets => {
  return {
    type: LIST,
    timesheets: timesheets
  };
};

export const get = timesheet => {
  return {
    type: GET,
    timesheet: timesheet
  };
};

export const setTimesheet = payload => {
  sessionStorage.setItem('select_timesheet', JSON.stringify(payload))
  return {
    type: SET_TIMESHEET,
    payload
  }
}

export const bulkSubmitTimes = data => (dispatch, getState) => {
  dispatch({
    type: BULK_SUBMIT_TIMES,
  })
  timesheets.submit(uid, data)
    .then(res => onBulkSubmitTimesSuccess({dispatch, getState, res}))
    .catch(err => onBulkSubmitTimesFailure({dispatch, err}))
}

function onBulkSubmitTimesSuccess({dispatch, getState, res}) {
  console.log('bulkSubmit success');
  const { timesheets } = getState()
  dispatch({
    type: BULK_SUBMIT_TIMES_SUCCESS,
    payload: [res, timesheets]
  })
  dispatch(push('/reports/timesheet/timesheets'))
}

function onBulkSubmitTimesFailure({dispatch, err}) {
  console.log('bulkSubmit failure', err);
  const payload = err.message || 'bulkSubmit Failed'
  window.alert(payload)
  dispatch({
    type: BULK_SUBMIT_TIMES_FAILURE,
    payload,
  })
}

export const assignTagToTimeInput = (uid, tagName, tagColor) => (dispatch, getState) => {
  dispatch({ 
    type: TIME_ENTRY_INPUT_ASSIGN_TAG
  })
  //assign tag to entry
  timesheets.updateText()
  .then(res => onAssignTagSuccess({dispatch, res}))
  .catch(err => onAssignTagFailure({dispatch, err}))
}

function onAssignTagSuccess({dispatch, res}) {
  dispatch({
    type: ASSIGN_TAG_SUCCESS,
    payload: res,
  })
}

function onAssignTagFailure({dispatch, err}) {
  const payload = err.message || 'AssignTag Failed'
  window.alert(payload)
  dispatch({
    type: ASSIGN_TAG_FAILURE,
    payload,
  })
  dispatch(push('/actions'))
}

export const assignTagToTimeEntry = (uid, entryId, tagName, tagColor) => dispatch => {
    dispatch({ 
      type: TIME_ENTRIES_ASSIGN_TAG
    })
    //assign tag to entry
    timesheets.updateEntry()
    .then(res => onAssignTagEntrySuccess({dispatch, res}))
    .catch(err => onAssignTagEntryFailure({dispatch, err}))
  }
  
  function onAssignTagEntrySuccess({dispatch, res}) {
    dispatch({
      type: TIME_ENTRIES_ASSIGN_TAG_SUCCESS,
      payload: res,
    })
  }
  
  function onAssignTagEntryFailure({dispatch, err}) {
    const payload = err.message || 'GetAction Failed'
    window.alert(payload)
    dispatch({
      type: TIME_ENTRIES_ASSIGN_TAG_FAILURE,
      payload,
    })
    dispatch(push('/timesheets'))

  }

export const assignTagToTimeStatus = (uid, entryId, tagId) => dispatch => {
  dispatch({ 
    type: TIME_ENTRIES_ASSIGN_STATUS
  })
  //assign tag to entry
  timesheets.updateStatus()
  .then(res => onAssignTagStatusSuccess({dispatch, res}))
  .catch(err => onAssignTagStatusFailure({dispatch, err}))
}

function onAssignTagStatusSuccess({dispatch, res}) {
  dispatch({
    type: TIME_ENTRIES_ASSIGN_STATUS_SUCCESS,
    payload: res,
  })
}

function onAssignTagStatusFailure({dispatch, err}) {
  const payload = err.message || 'AssignStatus Failed'
  window.alert(payload)
  dispatch({
    type: TIME_ENTRIES_ASSIGN_STATUS_FAILURE,
    payload,
  })
  dispatch(push('/timesheets'))

}

export const updateText = (uid, text) => dispatch => {
  dispatch({ 
    type: TIME_ENTRY_INPUT_CHANGE_TEXT
  })
  //assign tag to entry
  timesheets.updateText()
  .then(res => onUpdateTextSuccess({dispatch, res}))
  .catch(err => onUpdateTextFailure({dispatch, err}))
}

function onUpdateTextSuccess({dispatch, res}) {
  dispatch({
    type: TIME_ENTRY_INPUT_CHANGE_TEXT_SUCCESS,
    payload: res,
  })
}

function onUpdateTextFailure({dispatch, err}) {
  const payload = err.message || 'updateText Failed'
  window.alert(payload)
  dispatch({
    type: TIME_ENTRY_INPUT_CHANGE_TEXT_FAILURE,
    payload
  })
  dispatch(push('/timesheets'))

}

  export const updateTime = (uid, date) => (dispatch, getState) => {
    dispatch({
      type: TIME_ENTRY_INPUT_CHANGE_START_TIME, 
      payload: date
    })

    timesheets.updateTime(uid, date)
    .then(res => onUpdateTimeSuccess({dispatch, res}))
    .catch(err => onUpdateTimeFailure({dispatch, err}))
  }

  function onUpdateTimeSuccess({dispatch, res}) {
    dispatch({
      type: TIME_ENTRY_INPUT_CHANGE_START_TIME_SUCCESS,
      payload: res,
    })
  }
  
  function onUpdateTimeFailure({dispatch, err}) {
    const payload = err.message || 'updateTime Failed'
    window.alert(payload)
    dispatch({
      type: TIME_ENTRY_INPUT_CHANGE_START_TIME_FAILURE,
      payload,
    })
    dispatch(push('/timesheets'))
  
  }

  export const stop = (uid, text, date, time, project, timesheet) => dispatch => {
    const payload = {
      uid,
      text,
      startTime: date,
      time,
      tag: uid + '-' + time,
      project,
      timesheet
    }

    dispatch({
      type: TIME_ENTRY_INPUT_STOP, 
      payload
    })

    timesheets.timeStop(uid, payload)
    .then(res => onTimeStopSuccess({ dispatch, res }))
    .catch(err => onTimeStopFailure({ dispatch, err }))
  }

  function onTimeStopSuccess({dispatch, res}) {
    dispatch({
      type: TIME_ENTRY_INPUT_STOP_SUCCESS,
      payload: res,
    })
    window.location.reload();
  }

  function onTimeStopFailure({dispatch, err}) {
    const payload = err.message || 'timeStop Failed'
    window.alert(payload)
    dispatch({
      type: TIME_ENTRY_INPUT_STOP_FAILURE,
      payload,
    })
    dispatch(push('/timesheets'))

  }

//Pull tracking entry from server
export const getTimeEntry = (uid) => (dispatch, getState) => {
  // check if a action has been set yet
  // const { action } = getState().actions
  // if (action && action.id !== actionId) {
  //   // clear it if it is not the same one
  //   console.log('clearing state');
  //   dispatch({
  //     type: timesheetTypes.CLEAR_STATE,
  //   })
  // }
  dispatch({
    type: TIME_ENTRY_INPUT_PULL
  })
  
  timesheets.getEntryById(uid)
  .then(res => onGetTimeEntrySuccess({dispatch, res}))
  .catch(err => onGetTimeEntryFailure({dispatch, err}))
}

function onGetTimeEntrySuccess({dispatch, res}) {
  console.log('getEntry success');
  const payload = {
    text: res.text,
    startTime: res.startTime
  }
  if (res.tag) {
    payload.tagId = res.tag
  }
  dispatch({
    type: TIME_ENTRY_INPUT_PULL_SUCCESS,
    payload
  })
}

function onGetTimeEntryFailure({dispatch, err}) {
  const payload = err.message || 'getEntry Failed'
  window.alert(payload)
  dispatch({
    type: TIME_ENTRY_INPUT_PULL_FAILURE,
    payload: null,
  })
  dispatch(push('/timesheets'))
}

export const start = (uid, text, date, tagId) => dispatch => {
  const payload = {
    text,
    startTime: date,
    tagId
  }
  const entryData = {
    text,
    startTime: date,
  }
  
  if (tagId) {
    entryData.tag = tagId
  }

  dispatch({
    type: TIME_ENTRY_INPUT_START
  })
  
  timesheets.timeStart(uid, payload)
  .then(res => onTimeStartSuccess({dispatch, res}))
  .catch(err => onTimeStartFailure({dispatch, err}))
}

function onTimeStartSuccess({dispatch, res}) {
  console.log('timeStart success');

  dispatch({
    type: TIME_ENTRY_INPUT_PULL_SUCCESS,
    payload: res
  })
}

function onTimeStartFailure({dispatch, err}) {
  const payload = err.message || 'timeStart Failed'
  window.alert(payload)
  dispatch({
    type: TIME_ENTRY_INPUT_PULL_FAILURE,
    payload: null,
  })
  dispatch(push('/timesheets'))
}

export const removeTimeEntry = (uid) => dispatch => {
  dispatch({
    type: TIME_ENTRY_INPUT_REMOVE
  })
  
  timesheets.deleteById(uid)
  .then(res => onRemoveEntrySuccess({dispatch, res}))
  .catch(err => onRemoveEntryFailure({dispatch, err}))
}

function onRemoveEntrySuccess({dispatch, res}) {
  console.log('removeEntry success');
  dispatch({
    type: TIME_ENTRY_INPUT_REMOVE_SUCCESS,
    payload: res
  })
}

function onRemoveEntryFailure({dispatch, err}) {
  console.log('removeEntry failure', err);
  const payload = err.message || 'GetAction Failed'
  window.alert(payload)
  dispatch({
    type: TIME_ENTRY_INPUT_REMOVE_FAILURE,
    payload: null,
  })
  dispatch(push('/timesheets'))
}