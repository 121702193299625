import { api } from '../config'
// Cookies
import * as cookies from 'js-cookie'

export default {
    post(data) {
        const url = '/community'
        return api.post(url, data)
    },
    getAll() {
        const url = '/community'
        return api.get(url)
    },
    getById(id) {
        const url = `/community/${id}`
        return api.get(url)
    },

    patchById(id, update) {
        const url = `/community/${id}`
        console.log(update)
        return api.patch(url, update)
    },

    deleteById(id) {
        const url = `/community/${id}`
        return api.delete(url, id)
    },
    addPost(id, data) {
        const url = `/community/post/${id}`
        return api.post(url, data)
    }
}