// React
import React, { Component } from 'react';

// Redux
import { connect, Provider as ReduxProvider } from 'react-redux';
import { compose, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import reducers from './reducers'
import createLogger from 'redux-logger';

// Routing
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'

// Store + History
import { history } from './lib'
import * as cookies from 'js-cookie';

// Components
import Main from './components/Main/Main'
import Login from './components/Login/Login'
import restricted from './components/restricted'
import HrDashboard from './components/HrDashboard/HrDashboard';
// import LandingPage from './components/LandingPage/LandingPage';
// import MessagerPage from './components/MessagerPage/MessagerPage';

// Socket.io Messaging
import { constants } from './lib';

import localStorageService from './components/shared/localstorage';
import { init, getInstance, getStream } from './components/shared/socketclient';
import { changeLanguage } from './components/shared/i18n';

// import { connectSocket } from './actions/sockets';
// import { NativeScan, Upload } from './components/shared';
import MessagerPage from './components/Messager/MessagerPage';
import Pricing from './components/LandingPage/pages/Pricing/Pricing';
import NotFound from './components/NotFound/NotFound';
import Job from './components/LandingPage/pages/Career/components/Job';
import CareerPage from './components/LandingPage/pages/Career/CareerPage';
import MemberView from './components/LandingPage/pages/Community/MemberView/MemberView';
import pdfReader from './components/LandingPage/components/pdfReader/pdfReader';
import CommunityPage from './components/LandingPage/pages/Community/CommunityPage';
import { configureStore } from '@reduxjs/toolkit';

const  {
  socketURL,
  appVersion
} = constants

export default class App extends Component {
  constructor (props) {
    super (props);
    this.state = {
      channel: []
    };
    this.eventSource = init(socketURL);
  };

  componentDidMount() {
    const memory = navigator.deviceMemory;
    "geolocation" in navigator && navigator.geolocation.watchPosition(async (position) => {
      // const coords = new GeolocationCoordinates
      !navigator.gpu && console.error("GPU NOT SUPPORTED")
      const adapter = !!navigator.gpu && await navigator.gpu.requestAdapter();
      !adapter && console.error("Couldn't request WebGPU adapter.");
      const device = !!adapter && await adapter.requestDevice();
      // console.log(`This device has at least ${memory}GiB of RAM.`);
      localStorage.setItem('cli_lat', position.coords.latitude)
      localStorage.setItem('cli_long', position.coords.longitude)
      // localStorage.setItem('cli_coords', coords)
      localStorage.setItem('cli_gpu', device)
      localStorage.setItem('cli_ram', memory)      
    });
    
    this.eventSource.onmessage = e => {
      this.setState ({ channel: JSON.parse(e.data) })
    };
    this.eventSource.addEventListener("incoming", e => {
      sessionStorage.setItem('company', e.data)
      this.setState ({ channel: JSON.parse(e.data) })
    });
    this.eventSource.addEventListener("remove", e => {
      this.setState ({ channel: JSON.parse(e.data) })
    });
  };

  render() {

    /*
      init socket.io client and get its connection to the server
    */
    const stream = getStream()
    let socketClient
    if (stream === typeof WebSocket) {
      socketClient = {}
    } else {
      socketClient = getInstance() 
    }

    /*
      handles the data stored reset when the application version changes if that is needed
    */
    localStorageService.init(appVersion);
    const persistedState = localStorageService.loadState();

    // update the usec locale by the i18next from the localstorage
    changeLanguage (persistedState && persistedState.locale);

    // meld service worker for cache integrity
    document.addEventListener('load', (event) => {
      if ('serviceWorker' in navigator) {
        const serviceWorkerRegistration = new ServiceWorkerRegistration();
        serviceWorkerRegistration.unregister();
        event.waitUntil(
          caches.keys().then((cacheNames) =>
          Promise.all(cacheNames.filter((cacheName) => 
          cacheName.startsWith('workbox-precache-'))
          .map((cacheName) => caches.delete(cacheName))
          .then(() => serviceWorkerRegistration.register())))
        );
        setTimeout(function () { window.location.replace(""); }, 300)
      };
    });

    // create browser history to use in middleware
    const historyMiddleware = routerMiddleware(history)
    console.log(persistedState)
    const store = createStore(
      reducers(history),
      persistedState,
      compose(
        applyMiddleware(
          historyMiddleware, thunk.withExtraArgument(
            { socketClient, changeLanguage, createLogger }
          ),
        ),
      ),
    )

    /* 
      listeners 
    */
    // hack to reload the reducer
    // IMPORTANT: that won't work to this file, and you'll get an error on the browser console
    // https://github.com/reduxjs/react-redux/releases/tag/v2.0.0
    if( module.hot ){
      module.hot.accept(() => {
        const nextRootReducer = reducers
        store.replaceReducer(nextRootReducer);
      });
    }

    // listen the changes on the redux store and persist them on the session
    localStorageService.subscribe(
      store.subscribe,
      // defines a callback to get the data which will be persisted
      () => store.getState() 
    );

    return (
        <ReduxProvider store={store}>
          <ConnectedRouter history={history}>
            <Switch>    
              <Redirect
                exact
                from="/"
                to="/login"
              />          
              <Route
                path="/whitepaper"
                component={pdfReader}
              />
              <Route
                path="/login"
                component={Login}
              />
              <Route
                path="/pricing"
                component={Pricing}
              />
              <Route
                path="/chats"
                component={MessagerPage}
              />
              <Route
                path="/community"
                component={CommunityPage}
              />

              <Route
                path="/community/:id"
                component={MemberView}
              />

              <Route
                path="/careers"
                component={CareerPage}
              />
              
              <Route
                path="/careers/:id"
                component={Job}
              />

              <Route
                path="/hr"
                render={restricted(HrDashboard, 'auth')}
              />
              <Route
                path="/"
                render={restricted(Main, 'auth')}
              />
              <Route
                component={NotFound}
              />
            </Switch>
          </ConnectedRouter>
        </ReduxProvider>
    )
  }
}

