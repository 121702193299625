import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

// Components
import NotFound from '../../NotFound/NotFound';
import InvoicePage from './InvoicePage/components/Invoice';
import SheetView from './TimesheetPage/timesheets/SheetView/SheetView';
import Timesheets from './TimesheetPage/timesheets/Timesheets';

const ReportsPageRouter = ({ props }) => (
  <Switch>
    <Route
      path="/reports/invoice"
      component={InvoicePage}
    />
    
    <Route 
      path="/reports/timesheet" 
      component={ Timesheets } 
    />

    <Route 
      path="/reports/:id" 
      component={ SheetView } 
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default ReportsPageRouter
