import MediaDevice from './MediaDevice';
import Emitter from './Emitter';
import { socket, live } from './socket';

const PC_CONFIG = {
  iceServers: [
    {
        urls: ["stun:stun.muvor.com:3478"]
    },
    {
        username: "muvor",
        credential: "W4Kuu1@3",
        urls: [
            // "turn:turn.muvor.xyz:9000?=tcp",
            "turn:turn.muvor.com:3478"
        ]
    }
]};

class PeerConnection extends Emitter {
  /**
     * Create a PeerConnection.
     * @param {String} friendID - ID of the friend you want to call.
     */
  constructor() {
    super();
    this.pc = new RTCPeerConnection(PC_CONFIG);
    this.mediaDevice = new MediaDevice();
    this.pc.onicecandidate = e => {
      socket.emit('call', {
        to: this.friendID,
        candidate: e.candidate
      });
    }
    this.pc.onlivecandidate = e => {
      live.emit('start', {
        candidate: e.candidate
      });
    }
  }

  /**
   * Starting the call
   * @param {Boolean} isCaller
   * @param {Object} config - configuration for the call {audio: boolean, video: boolean}
   */
  start(isCaller, friendID, config) {
    this.pc.ontrack = e => this.emit('peerStream', e.streams[0]);
    this.friendID = friendID
    this.mediaDevice
      .on('stream', (stream) => {
        stream.getTracks().forEach((track) => {
          this.pc.addTrack(track, stream);
        });
        this.emit('localStream', stream);
        if (isCaller) socket.emit('request', { to: friendID });
        else this.createOffer();
      })
      .start(config);

    return this;
  }

  /**
   * Stop the call
   * @param {Boolean} isStarter
   */
  stop(isStarter) {
    if (isStarter) {
      socket.emit('end', { to: this.friendID });
    }
    this.mediaDevice.stop();
    this.pc.close();
    this.pc = null;
    this.off();
    return this;
  }

  createOffer() {
    this.pc.createOffer()
      .then(this.getDescription.bind(this))
      .catch((err) => console.log(err));
    return this;
  }

  createAnswer() {
    this.pc.createAnswer()
      .then(this.getDescription.bind(this))
      .catch((err) => console.log(err));
    return this;
  }

  getDescription(desc) {
    this.pc.setLocalDescription(desc);
    socket.emit('call', { to: this.friendID, sdp: desc });
    return this;
  }


  /**
   * Go Live
   * @param {Boolean} isMe
   * @param {Object} config - configuration for the call {audio: boolean, video: boolean}
   */
  go(isMe, config) {
    this.pc.ontrack = e => this.emit('peerStream', e.streams[0]);
    this.mediaDevice
      .on('stream', (stream) => {
        stream.getTracks().forEach((track) => {
          this.pc.addTrack(track, stream);
        });
        this.emit('liveStream', stream);
        isMe ? live.emit('offer') : this.createOffer();
      }).start(config);
    return this;
  }

  /**
   * End live
   * @param {Boolean} isStarter
   */
  end(isStarter) {
    if (isStarter) {
      live.emit('end', { to: this.friendID });
    }
    this.mediaDevice.stop();
    this.pc.close();
    this.pc = null;
    this.off();
    return this;
  }

  // getDescription(desc) {
  //   this.pc.setLocalDescription(desc);
  //   live.emit('live', { to: this.friendID, sdp: desc });
  //   return this;
  // }

  /**
   * @param {Object} sdp - Session description
   */
   setRemoteDescription(sdp) {
    const rtcSdp = new RTCSessionDescription(sdp);
    this.pc.setRemoteDescription(rtcSdp);
    return this;
  }

  /**
   * @param {Object} candidate - ICE Candidate
   */
  addIceCandidate(candidate) {
    if (candidate) {
      this.pc.addIceCandidate(new RTCIceCandidate(candidate));
    }
    return this;
  }
}

export default PeerConnection;
