import React from 'react';
import cn from 'classnames'

import {
  SummaryItem,
} from '../../common'

import styles from './SummaryRow.css'

const SummaryRow = ({ className, user, view, times }) => {
  const {
    lastLogin,
    hours,
    bonus,
    totalBonus,
    revenue,
    rate
  } = user || {};

  let weeklyHours, ytdBonus, tBonus, monthlyRevenue
  if (view === 'day') {
      weeklyHours=hours*1
      ytdBonus=bonus*1
      tBonus=totalBonus*1
      monthlyRevenue=revenue*1
  } else if (view === 'week') {
      weeklyHours=hours*7
      ytdBonus=bonus*7
      tBonus=totalBonus*7
      monthlyRevenue=revenue*7
  } else if (view === 'month') {
      weeklyHours=hours*30
      ytdBonus=bonus*30
      tBonus=totalBonus*30
      monthlyRevenue=revenue*30
  } else if (view === 'year') {
      weeklyHours=hours*365
      ytdBonus=bonus*365
      tBonus=totalBonus*365
      monthlyRevenue=revenue*365
  } else {
      weeklyHours=hours*1
      ytdBonus=bonus*1
      tBonus=totalBonus*1
      monthlyRevenue=revenue*1
  }

  function formatTime(milliseconds) {
    let ms = Math.floor(milliseconds % 1000); // Extract milliseconds
    let seconds = Math.floor((milliseconds / 1000) % 60); // Convert to seconds
    let minutes = Math.floor((milliseconds / (1000 * 60)) % 60); // Convert to minutes
    let hours = Math.floor(milliseconds / (1000 * 60 * 60)); // Convert to hours
  
    // Ensure double digits for consistency
    let formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
      ms.toString().padStart(2, '0')
    ].join(':');
  
    return formattedTime;
  }


  const items = !!times ? [
    {
      title: 'Day',
      content: new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(new Date()),
    },
    {
      title: 'Total Time',
      content: formatTime(times.times.reduce((accumulator, currentValue) => accumulator + currentValue.time, 0)) || '...',
    },
    {
      title: 'Prepay',
      content: (times.times.reduce((accumulator, currentValue) => accumulator + currentValue.time, 0))*rate || '...',
    },
    {
      title: 'Avg. Weekly',
      content: hours || '...',
    },
    {
      title: 'Last Logged In',
      content: lastLogin || '...',
    },
  ] : [
    {
      title: 'Bonus',
      content: tBonus || '...',
    },
    {
      title: 'Avg Monthly',
      content: monthlyRevenue || '...',
    },
    {
      title: 'Bonus Year-to-Date',
      content: ytdBonus || '...',
    },
    {
      title: 'Hours Online Weekly',
      content: weeklyHours || '...',
    },
    {
      title: 'Last Logged In',
      content: lastLogin || '...',
    },
  ];
  const classNames = cn('summary-row', styles.summary, className)
  console.log(times)
  return (
    <div className={classNames}>
      {items.map(({ title, content }) => (
        <SummaryItem
          key={title}
          title={title}
          content={content}
        />
      ))}
    </div>
  )
}

export default SummaryRow
