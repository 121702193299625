// Router
import { push } from 'react-router-redux'

// Types
import { employeesTypes } from './types'

// Services
import { employees, stripes } from '../services'

// cookies 
import * as cookies from 'js-cookie'

const {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_SUCCESS,
  SET_EMPLOYEE,
  GET_EMPLOYEE,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_SUCCESS,
  PATCH_EMPLOYEE,
  PATCH_EMPLOYEE_FAILURE,
  PATCH_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = employeesTypes

export const createEmployee = payload => dispatch => {
  dispatch({
    type: CREATE_EMPLOYEE,
  })
  stripes.patchStripe(payload)
    .then(res => onCreateEmployeeSuccess({dispatch, res}))
    .catch(err => onCreateEmployeeFailure({dispatch, err}))
}

function onCreateEmployeeSuccess({dispatch, res}) {
  console.log('createEmployee success');
  dispatch({
    type: CREATE_EMPLOYEE_SUCCESS,
  })
  dispatch(push('/employees'))
}

function onCreateEmployeeFailure({dispatch, err}) {
  console.log('createEmployee failure', err);
  const payload = err.message || 'CreateEmployee Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_EMPLOYEE_FAILURE,
    payload,
  })
}

export const setEmployee = payload => {
  sessionStorage.setItem('select_employee', JSON.stringify(payload))
  return {
    type: SET_EMPLOYEE,
    payload,
  }
}

export const getEmployeeById = employeeId => (dispatch, getState) => {
  // check if a employee has been set yet
  const { employee } = getState().employees
  if (employee && employee.id !== employeeId) {
    // clear it if it is not the same one
    console.log('clearing state');
    dispatch({
      type: CLEAR_STATE,
    })
  }

  dispatch({
    type: GET_EMPLOYEE,
  })
  employees.getById(employeeId)
    .then(res => onGetEmployeeSuccess({dispatch, res}))
    .catch(err => onGetEmployeeFailure({dispatch, err}))
}

function onGetEmployeeSuccess({dispatch, res}) {
  console.log('getEmployee success');
  dispatch({
    type: GET_EMPLOYEE_SUCCESS,
    payload: res.data[0],
  })
}

function onGetEmployeeFailure({dispatch, err}) {
  console.log('getEmployee failure', err);
  const payload = err.message || 'GetEmployee Failed'
  window.alert(payload)
  dispatch({
    type: GET_EMPLOYEE_FAILURE,
    payload,
  })
  dispatch(push('/employees'))
}

export const patchEmployeeById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_EMPLOYEE,
  })
  employees.patchById(id, update)
    .then(res => onPatchEmployeeSuccess({dispatch, res}))
    .catch(err => onPatchEmployeeFailure({dispatch, err}))
}

function onPatchEmployeeSuccess({dispatch, res}) {
  console.log('patchEmployee success');
  dispatch({
    type: PATCH_EMPLOYEE_SUCCESS,
    payload: res.employee,
  })
}

function onPatchEmployeeFailure({dispatch, err}) {
  console.log('patchEmployee failure', err)
  const payload = err.message || 'PatchEmployee Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_EMPLOYEE_FAILURE,
    payload,
  })
}

export const createEmployeeNote = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_NOTE,
  })

  const {
    employeeId,
    text,
  } = data

  employees.postNoteById(employeeId, { text })
    .then(res => onCreateEmployeeNoteSuccess({dispatch, getState, res}))
    .catch(err => onCreateEmployeeNoteFailure({dispatch, err}))
}

function onCreateEmployeeNoteSuccess({dispatch, getState, res}) {
  console.log('createEmployeeNote success');
  const { note } = res

  // update the visits array on the employee
  const { employee } = getState().employees
  const notes = [note, ...employee.notes]
  employee.notes = notes

  dispatch({
    type: CREATE_NOTE_SUCCESS,
    payload: { ...employee },
  })
}

function onCreateEmployeeNoteFailure({dispatch, err}) {
  console.log('createEmployeeNote failure', err);
  dispatch({
    type: CREATE_NOTE_FAILURE,
    payload: err.message || 'CreateEmployeeNote Failed',
  })
}

export const deleteEmployeeById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_EMPLOYEE,
  })
  employees.deleteById(id)
    .then(res => onDeleteEmployeeSuccess({dispatch, getState}))
    .catch(err => onDeleteEmployeeFailure({dispatch, err}))
}

// TODO: REFACTOR - HOT RELOAD
function onDeleteEmployeeSuccess({dispatch, getState}) {
  console.log('DeleteEmployee success');
  const employee = getState().employees
  // const employees = employee.filter(el => el.id !== employee.id)

  dispatch({
    type: DELETE_EMPLOYEE_SUCCESS,
    payload: employee,
  })
}

function onDeleteEmployeeFailure({dispatch, err}) {
  console.log('DeleteEmployee failure', err)
  const payload = err.message || 'DeleteEmployee Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_EMPLOYEE_FAILURE,
    payload,
  })
}


export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}
