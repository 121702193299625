import React, { Component } from 'react';
import { connect } from 'react-redux'

// Components
import {
  Header,
  Button,
  Selector,
  SummaryItem,
  Table,
  Span,
  Icon,
} from '../../../../../common'

import {
  getOfferings,
  addToCart
} from '../../../../../../actions/community'

import styles from './OfferingsTab.css'
import OfferingsModal from './OfferingsModal/OfferingsModal';
import { formatCurrencyOptions } from '../../../../../../lib';

class OfferingsTab extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState
  }

  get initialState() {
    const {
    } = this.props

    return {
      editing: null,
      assignmentModal: null,
      offerModal: null,
      name: '',
      description: '',
      price: 0,
      staticPrice: [],
      payout: 0,
    }
  }

  get formIsInvalid() {
    const {
      quantity,
      note,
      cost
    } = this.state

    return !(
      quantity
      && note
      && cost
    )
  }

  componentDidMount() {
    this.props.getOfferings()
  }

  setEditState(id) {
    this.setState({
      ...this.initialState,
      editing: id,
    })
  }

  addToCart(offering) {
    const {
      _id,
      name,
      description,
      photos,
      documents,
      prices,
    } = offering

    const data = {
      offering: _id,
      user: this.props.me.id || this.props.me._id,
      quantity: 1,
      name,
      description,
      photo: photos[0],
      document: documents[0],
      price: prices[0],
    }
    this.props.addToCart(data)
  }

  save() {
    var {
      quantity,
      cost,
      note,
    } = this.state

    const regex = "^-?\\d*(\\.\\d+)?$"
    var validUpdate = true

    if (quantity === undefined || quantity.match(regex)){
      if (quantity === "") {
        quantity = undefined
      }
      parseInt(quantity)
      validUpdate = true
    } else {
      window.alert("error: Invalid value found") 
      return validUpdate = false
    }

    if (cost === undefined || cost.match(regex)){
      if (cost === "") {
        cost = undefined
      }
      parseInt(cost) 
      validUpdate = true
    } else {
      window.alert("error: Invalid value found")
      return validUpdate = false
    }

    const update = {
      quantity,
      cost,
      note,
    }

    const id = this.props.product._id

    if (validUpdate === true) {
      this.props.patchProductById(id, update)
    } else {
      return
    }

    this.setState({ editing: false })
  }

  delete() {
    const message = `Are you sure you want to delete this product?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.props.product._id
    this.props.deleteProductById(id)

    console.log(this.props.product._id)
  }

  renderIcons(offering) {
    const { editing } = this.state
    let selectedEdit = editing===offering._id
    if (selectedEdit) {
      return (
        <td className="icons">
          <Icon
            small
            trash
            onClick={() => this.delete()}
          />
          <Icon
            small
            save
            onClick={() => this.save()}
          />
          <Icon
            small
            cancel
            onClick={() => this.setEditState(false)}
          />
        </td>
      )
    } else {
      return (
        <td className={styles.detailsCell}>
          <Button
            title="ADD TO CART"
            onClick={() => this.addToCart(offering)}
          />
        {!!this.props.isme 
        && <Button
          small
          className={styles.editButton}
          title="EDIT"
          onClick={() => this.setEditState(offering._id)}
        />}
        </td>
      )
    }
  }

  renderPhotoLink(photo, index) {
    return (
      <a
        className="photo-link"
        href={photo.url}
        target="_blank"
        rel="noopener noreferrer"
        key={photo.id}
      >
        <img src={photo} />
      </a>
    )
  }

  renderTableHead() {
    return (
      <thead className={styles.head}>
        <tr>
          <th>
            Offering
          </th>
          <th>
            Description
          </th>
          <th>
            Photos
          </th>
          <th>
            Files
          </th>
          <th>
            Price
          </th>
          {this.props.me &&
          <th>
            Payout
          </th>}
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const {
      offerings
    } = this.props
    // TODO: Produces Warning: Change to ternary
    if (this.props.isme) {
      if (!offerings.length > 0) {
        return (
          <tbody>
            <tr colSpan="5">
              <Button times onClick={() => this.setState({ offerModal: {} })}>
                Create Offering
              </Button>
            </tr>
          </tbody>
        )
      } else {
        return (
          <tbody>
            <tr colSpan="5">
              <Button times onClick={() => this.setState({ offerModal: {} })}>
                Create Offering
              </Button>
            </tr>
            {offerings.map(this.renderTableRow.bind(this))}
          </tbody>
        )
      }
    } else if (!this.props.isme) {
      if (!offerings.length > 0) {
        return (
          <tbody>
            <tr>
              <td className="empty">
                No Offerings Found
              </td>
            </tr>
          </tbody>
        )
      } else {
        return (
          <tbody>
            {offerings.map(this.renderTableRow.bind(this))}
          </tbody>
        )
      }
    }
  }

  renderTableRow(offering) { 
    const {
      editing,
      name,
      description,
      price,
      payout,
    } = this.state  
    let selectedEdit = editing===offering._id
    return (
      <tr className={styles.map} key={offering._id}>
        <td>
          <Span
            editing={selectedEdit}
            value={name}
            placeholder={offering.name}
            onChange={name => this.setState({ name })}
          >
            {offering.name}
          </Span>
        </td>
        <td>
        <Span
            editing={selectedEdit}
            value={description}
            placeholder={offering.description}
            onChange={description => this.setState({ description })}
          >
            {offering.description || 'None'}
          </Span>
        </td>
        <td className={styles.email}>
          {selectedEdit && (<Button small title="Add Photos"/> )}
          {offering.photos && selectedEdit ? (
            offering.photos.map(p => <div><img src={p} /><Icon trash small /></div>)
            ) : offering.photos.length ? (
            offering.photos.map(p => <div><img src={p} /></div>)
          ) : (
            'No Photos'
          )}
        </td>
        <td>
          {selectedEdit && (<Button small title="Add Documents"/> )}
          {offering.documents && selectedEdit ? (
            offering.documents.map(d => <div><img src={d} /><Icon trash small /></div>)
            ) : offering.documents.length ? (
              offering.documents.map(d => <div><img src={d} /></div>)
            ) : (
              'No Documents'
          )}
        </td>
        <td className="bold">
          {selectedEdit ? (
            offering.prices.map(p => <div>{p.toLocaleString(...formatCurrencyOptions)}<Icon add small /></div>)
          ) : (
            offering.prices.map(p => <div>{p.toLocaleString(...formatCurrencyOptions)}</div>)
          )}
        </td>
        {this.props.me &&
        <td className="bold">
          {offering.payout.toLocaleString(...formatCurrencyOptions)}
        </td>}
        {this.renderIcons(offering)}
      </tr>
    )
  }

  render() { 
    if (!!this.state.offerModal) {
      return (
        <OfferingsModal
          coin={this.props.coin}
          content={this.state.offerModal}
          onClickAway={() => this.setState({offerModal: null})}
        />
      )
    }
    return ( 
      <Table className={styles.app}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    );
  }
}

const mapStateToProps = ({ community }) => {
  const {
    offerings
  } = community

  return {
    offerings
  }
}

const actions = {
  getOfferings,
  addToCart
}

export default connect(mapStateToProps, actions)(OfferingsTab);
