import React, { Component } from 'react';
import { connect } from 'react-redux'

import { hasAuthTokenAsync } from '../../../../../../lib'

// Components
import {
  SwitchTable,
} from '../../../../../shared'

import {
  Input,
  Icon,
  Header,
  Body,
  Span,
} from '../../../../../common'
import DashboardTab from './DashboardTab/DashboardTab'

// Actions
import {
  getMemberById,
  patchMemberById,
  deleteTeamById
} from '../../../../../../actions/team';
import {
  getSheetById,
  getAdminSheets,
  removeTimesheet,
  removeTimeEntry,
  updateTimesheet,
  updateTime,
  updateText
} from '../../../../../../actions/timesheets';
import * as cookies from 'js-cookie';

import styles from './SheetView.css'
import SuperTable from './SuperTable/SuperTable';

class SheetView extends Component {
  constructor(props) {
    super(props)
    this.me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined;
    this.sheet = sessionStorage.getItem('select_timesheet') ? sessionStorage.getItem('select_timesheet') : undefined;
    this.tabOptions = this.me != undefined && this.me.role === 'Admind' ? [
      {
        value: 'times',
        display: 'My Times',
        renderComponent: () => this.renderDashboardTab(),
      },
      {
        value: 'old',
        display: 'Past Times',
        renderComponent: () => this.renderScriptsTab(),
      },
      {
        value: 'requests',
        display: 'Requests',
        renderComponent: () => this.renderSupervisorsTab()
      }
    ] : this.me.role === 'Admin' ? [
      {
        value: 'times',
        display: 'Times',
        renderComponent: () => this.renderDashboardTab(),
      },
      {
        value: 'payouts',
        display: 'Payouts',
        renderComponent: () => this.renderScriptsTab(),
      },
      {
        value: 'requests',
        display: 'Requests',
        renderComponent: () => this.renderSupervisorsTab(),
      }
    ] : [
      {
        value: 'times',
        display: 'This Week',
        renderComponent: () => this.renderDashboardTab(),
      },
      {
        value: 'old',
        display: 'Past Times',
        renderComponent: () => this.renderScriptsTab(),
      }
    ]

    this.state = this.initialState
  }

  get initialState() {
    const { user } = this.props
    const {
      firstName,
      lastName,
      group,
      email,
    } = user

    return {
      tab: this.tabOptions[0],
      editing: false,
      firstName,
      lastName,
      group,
      email,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return nextProps.user || null
  }

  componentDidMount() {
    hasAuthTokenAsync()
    .then(() => {
      this.me.role === 'Supervisor' || 'Admin' && this.props.getAdminSheets(this.me.company)
      this.props.getSheetById(this.props.match.params.id)
      this.props.getMemberById(this.me.company)
    }).catch(console.log)
  }

  setEditState(editing) {
    this.setState({ ...this.initialState, editing })
  }

  save() {
    const id = this.props.user.id
    const {
      firstName,
      lastName,
      username,
      group,
      email,
    } = this.state

    const update = {
      firstName,
      lastName,
      username,
      group,
      email,
    }

    this.props.patchMemberById(id, update)

    this.setEditState(false)
  }

  // handleSave = (timesheet) => {
  //   const { history, createTimesheet, updateTimesheet } = this.props;

  //   const save = timesheet._id ? updateTimesheet(timesheet) : createTimesheet(timesheet);
  //   save.then(() => {
  //     history.push('/employees/timesheets');
  //   });
  // };

  delete() {
    const message = `Are you sure you want to delete this team?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.props.team._id
    this.props.deleteTeamById(id)
  }

  renderSwitchTable() {
    const { tab } = this.state
    return (
      <SwitchTable
        tabs={this.tabOptions}
        selected={tab}
        onClick={tab => this.setState({ tab })}
      />
    )
  }

  renderDashboardTab() {
    return (
      <DashboardTab user={this.me} sheet={this.sheet} times={this.props.times[0]}/>
    )
  }

  renderScriptsTab() {
    return (
      <div className={styles.scriptsTab}>
        <div className="header">
          Scripts
        </div>
      </div>
    )
  }

  renderSupervisorsTab() {
    const { adminSheets } = this.props;
    return (
      Array.isArray(adminSheets) 
      ? <SuperTable times={adminSheets}/>
      : <div className={styles.scriptsTab}>
        <div className="header">
          Approvals list
        </div>
      </div>
    );
  };

  renderContactInfo() {
    const {
      editing,
      firstName,
      lastName,
      username,
      group,
      email,
    } = this.state

    const {
      times
    } = this.props

    return times.length > 0 &&
      <div className={styles.contactInfo}>
        {editing ? (
          <div className="name">
            Name:
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={firstName => this.setState({ firstName })}
            />
            <Input
              placeholder="Last Name"
              value={lastName}
              onChange={lastName => this.setState({ lastName })}
            />
          </div>
        ) : (
          <div>
            Name:
            <Span>
              {this.me.name}
            </Span>
          </div>
        )}
        <div>
          Email:
          <Span
            editing={editing}
            value={email}
            type="email"
            placeholder="user@gmail.com"
            onChange={email => this.setState({ email })}
          >
            {this.me.email || 'None'}
          </Span>
        </div>
        <div>
          Supervisor:
          <Span
            editing={editing}
            value={times[0].supervisors.map((s) => s.name)}
            placeholder="Group Name"
            onChange={group => this.setState({ group })}
          >
            {times[0].supervisors.map((s) => `${s.name}\n`) || 'None'}
          </Span>
        </div>
        <div>
          Notes:
          <Span
            editing={editing}
            value={times[0].notes}
            placeholder="Username"
            onChange={username => this.setState({ username })}
          >
            {times[0].notes || 'None'}
          </Span>
        </div>
      </div>
  }

  render() {
    const { user } = this.props
    if (!user) {
      return null
    }

    const {
      editing,
    } = this.state

    return (
      <div>
        <Body className={styles.body}>
          {!editing ? (
            <div className={styles.icons}>
              <Icon
                edit
                onClick={() => this.setEditState(true)}
              />
              <Icon
                cancel
                onClick={() => this.delete()}
              />
            </div>
          ) : (
            <div className={styles.icons}>
              <Icon
                cancel
                onClick={() => this.setEditState(false)}
              />
              <Icon
                save
                onClick={() => this.save()}
              />
            </div>
          )}
          {this.renderContactInfo()}

          <div className="switch-buffer" />

          {this.renderSwitchTable()}
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, team, timesheets }) => {
  const {
    user,
    error,
  } = team

  const {
    me,
  } = auth

  const {
    timesheetDisplay,
    adminSheets,
    times
  } = timesheets

  return {
    sheet: timesheetDisplay,
    user: user || {},
    adminSheets,
    times,
    error,
    me,
  }
}

const actions = {
  getSheetById,
  getAdminSheets,
  patchMemberById,
  getMemberById,
  deleteTeamById
}

export default connect(mapStateToProps, actions)(SheetView);
