import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

// Components
import AgendaPage from './AgendaPage/AgendaPage'
import ContactsPage from './ContactsPage/ContactsPage'
import DashboardPage from './DashboardPage/DashboardPage'
import EmployeesPage from './EmployeesPage/EmployeesPage'
import ProductsPage from './ProductsIn/ProductsPage'
import * as WritingPage from './HandwritingPage/helpers/atrament'
// import * as ProductsOut from './ProductsOut/ProductsPage'
// import RepsPage from './RepsPage/RepsPage'
import EditorPage from './EditorPage/EditorPage'
import ScriptsPage from './SalesPage/ScriptsPage'
import RefillsPage from './SearchPage/RefillsPage'
import SupervisorsPage from './SupervisorsPage/SupervisorsPage'
import TeamPage from './TeamPage/TeamPage'
import ImportPage from './ImportPage/ImportsPage'
import BankPage from './BankPage/BankPage'
import ProjectsPage from './ProjectsPage/ProjectsPage';

// Dropdown
import CalculatorPage from './CalculatorPage/CalculatorPage'
import ProfilePage from './ProfilePage/ProfilePage'
import ReportsPage from './ReportsPage/ReportsPage'

// Fallback
import NotFound from '../NotFound/NotFound'

// Restrictions
import restricted from '../restricted'
import Timesheet from './ReportsPage/TimesheetPage/Timesheet';

const MainRouter = ({ props, history, location }) => (
  <Switch>
    <Redirect
      exact
      from="/"
      to="/dashboard"
    />
    <Route
      path="/dashboard"
      render={() => <DashboardPage
        comp={props}
        history={history}
        location={location}
        />
      }
    />

    <Route
      path="/agenda"
      render={() => <AgendaPage
        comp={props}
        history={history}
        location={location}        
        />
      }
    />

    <Route
      path="/import"
      render={() => <ImportPage
        comp={props}
        history={history}
        location={location}        
        />
      }
    />

    <Route
      path="/supervisors"
      render={() => <SupervisorsPage
        comp={props}
        history={history}
        location={location}
        />
      }
    />
    <Route
      path="/team"
      render={() => <TeamPage
        comp={props}
        history={history}
        location={location}
        />
      }
    />    
    <Route
      path="/bank"
      render={() => <BankPage
        comp={props}
        history={history}
        location={location}
        />
      }
    />  
    <Route
      path="/products"
      render={() => <ProductsPage
        comp={props}
        history={history}
        location={location}
        />
      }
    /> 
    <Route
      path="/projects"
      render={() => <ProjectsPage
        comp={props}
        history={history}
        location={location}
        />
      }
    /> 
    <Route
      path="/contacts"
      render={() => <ContactsPage
        comp={props}
        history={history}
        location={location}
        />
      }
    /> 
    <Route
      path="/calculator"
      render={() => <CalculatorPage
        comp={props}
        history={history}
        location={location}
        />
      }
    />     
    <Route
      path="/editor"
      render={() => <EditorPage
        comp={props}
        history={history}
        location={location}
        />
      }
    />  
    <Route
      path="/writer"
      render={() => <WritingPage
        comp={props}
        history={history}
        location={location}
        />
      }
    /> 
    <Route
      path="/reports"
      render={() => <ReportsPage
        comp={props}
        history={history}
        location={location}
        />
      }
    /> 
    <Route
      path="/profile"
      render={() => <ProfilePage
        comp={props}
        history={history}
        location={location}
        />
      }
    /> 
        

    {/* TODO: add props to restricted function */}
    <Route
      path="/orders"
      render={restricted(ScriptsPage, 'auth', 'Admin')}
    />

    <Route
      path="/sales"
      render={restricted(RefillsPage, 'auth', 'Admin')}
    />

    <Route
      path="/employees"
      render={restricted(EmployeesPage, 'auth', 'Admin')}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default MainRouter
