import { timesheetTypes } from '../actions/types';

const {
  TIME_ENTRY_INPUT_PULL,
  TIME_ENTRY_INPUT_PULL_SUCCESS,
  TIME_ENTRY_INPUT_PULL_FAILURE,
  TIME_ENTRY_INPUT_START,
  TIME_ENTRY_INPUT_STOP,
  TIME_ENTRY_INPUT_STOP_SUCCESS,
  TIME_ENTRY_INPUT_STOP_FAILURE,
  TIME_ENTRY_INPUT_REMOVE,
  TIME_ENTRY_INPUT_REMOVE_SUCCESS,
  TIME_ENTRY_INPUT_REMOVE_FAILURE,
  TIME_ENTRY_INPUT_ASSIGN_TAG,
  TIME_ENTRIES_ASSIGN_TAG,
  TIME_ENTRIES_ASSIGN_TAG_SUCCESS,
  TIME_ENTRIES_ASSIGN_TAG_FAILURE,
  TIME_ENTRIES_ASSIGN_STATUS,
  TIME_ENTRIES_ASSIGN_STATUS_SUCCESS,
  TIME_ENTRIES_ASSIGN_STATUS_FAILURE,
  TIME_ENTRY_INPUT_CHANGE_TEXT,
  TIME_ENTRY_INPUT_CHANGE_TEXT_SUCCESS,
  TIME_ENTRY_INPUT_CHANGE_TEXT_FAILURE,
  TIME_ENTRY_INPUT_CHANGE_START_TIME,
  TIME_ENTRY_INPUT_CHANGE_START_TIME_SUCCESS,
  TIME_ENTRY_INPUT_CHANGE_START_TIME_FAILURE
} = timesheetTypes;

const timeEntryInput = (state = {}, action) => {
  switch (action.type) {
    case TIME_ENTRY_INPUT_START:
      let newState = { 
        text: action.payload.text,
        startTime: action.payload.startTime,
        isFetching: action.isFetching,
      }
      if (action.payload.tagId) {
        newState.tagId = action.payload.tagId
      }

      if (
        (action.isFetching && action.isFetching === true) 
        ||
        (action.status && action.status === 'success')
      ) {
        return newState
      }

      return state

    case TIME_ENTRY_INPUT_PULL:
      if (action.status && action.status === 'success' && action.payload) {
        return {
          text: action.payload.text,
          startTime: action.payload.startTime,
          isFetching: action.isFetching,
          tagId: action.payload.tagId
        }
      } else {
        return Object.assign({}, state, {isFetching: action.isFetching})
      }
    
    case TIME_ENTRY_INPUT_STOP:
      if (action.status && action.status === 'success') {
        return {}
      } else {
        return Object.assign({}, state, {isFetching: action.isFetching})
      }

    case TIME_ENTRY_INPUT_CHANGE_TEXT:
      if (
        (action.status && action.status === 'success')
        ||
        (action.isFetching)
      ) {
        return Object.assign({}, state, {
          text: action.payload.text,
          isFetching: action.isFetching
        })
      } else {
        return state
      }

    case TIME_ENTRY_INPUT_CHANGE_START_TIME:
      if (
        (action.status && action.status === 'success')
        ||
        (action.isFetching)
      ) {
        return Object.assign({}, state, {
          startTime: action.payload.startTime,
          isFetching: action.isFetching
        })
      } else {
        return state
      }       

    case TIME_ENTRY_INPUT_REMOVE:
      if (action.status && action.status === 'success') {
        return {
          removedSuccess: true, 
          isFetching: false
        }
      } 
      if (action.isFetching && action.isFetching === true) {
        return Object.assign({}, state, {
          isFetching: true
        })
      }
      return state

    case TIME_ENTRY_INPUT_ASSIGN_TAG:
      if (action.isFetching && action.isFetching === true) {
        return Object.assign({}, state, {
          isFetching: true
        })
      }

      if (
        (action.status && action.status === 'success')
      ) {
        return Object.assign({}, state, {
          tagId: action.payload.tagId,
          isFetching: false
        })
      }
      
      return state

    case TIME_ENTRIES_ASSIGN_TAG:
      if (action.isFetching && action.isFetching === true) {
        return Object.assign({}, state, {
          tagId: action.payload.tagId,
          isFetching: true
        })
      }

      if (
        (action.status && action.status === 'success')
      ) {
        return Object.assign({}, state, {
          tagId: action.payload.tagId,
          isFetching: false
        })
      }
      
      return state      

    default :
      return state
  }
}

export default timeEntryInput