import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  AsYouType,
  parseNumber,
  formatNumber,
} from '../../../../lib/phoneHelper'

import {
  unformatDate,
} from '../../../../lib/dateHelper'

import { hasAuthTokenAsync } from '../../../../lib'

// Components
import {
  SwitchTable,
  AddressModal,
  AddressLine,
} from '../../../shared'

import {
  Input,
  Icon,
  Header,
  Body,
  Span,
} from '../../../common'

import NotesTab from './Tabs/NotesTab'


// Actions
import {
  createEmployeeNote,
  getEmployeeById,
  patchEmployeeById,
  deleteEmployeeById,
} from '../../../../actions/employees'

import styles from './EmployeeView.css'

class EmployeeView extends Component {
  constructor(props) {
    super(props)
    this.employee = sessionStorage.getItem('select_employee') ? JSON.parse(sessionStorage.getItem('select_employee')) : null
    this.tabOptions = [
      {
        value: 'notes',
        display: 'More Info/Notes',
        renderComponent: () => this.renderNotesTab(),
      },
    ]

    this.state = {
      tab: this.tabOptions[0],
      ...this.initialState,
    }
  }
  
  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        const { employeeId } = this.props.match.params
        this.props.getEmployeeById(employeeId)
      }).catch(console.log)
  }

  get initialState() {
    const { employee } = this.props
    const {
      name,
      email,
      address,
      role,
      dob,
      primaryInsurance,
      secondaryInsurance,
      warning,
    } = employee

    const phone = formatNumber(employee.phone)

    const contactInfoState = {
      name: name || '',
      email: email || '',
      phone,
      dob: dob || '1970-01-01',
      role
    }

    const notesState = {
      warning,
    }

    const insuranceState = {
      primaryInsurance: primaryInsurance || {},
      secondaryInsurance: secondaryInsurance || {},
    }

    const addressState = {
      address,
      addressModal: null,
    }

    return {
      editing: false,
      ...contactInfoState,
      ...notesState,
      ...insuranceState,
      ...addressState,
    }
  }

  setEditState(editing) {
    this.setState({ ...this.initialState, editing })
  }

  createNote({ text }) {
    const employeeId = this.employee._id
    const data = {
      text,
      employeeId,
    }
    this.props.createEmployeeNote(data)
  }

  closeModal() {
    this.setState({
      noteModal: null,
      addressModal: null,
    })
  }

  handlePhoneChange(val) {
    if (val.length > 16) {
      return
    }

    const phone = new AsYouType('US').input(val)

    this.setState({ phone })
  }

  insuranceHasFields(data) {
    return (
      data.plan
      || data.bin
      || data.planId
      || data.pcn
      || data.type
    )
  }

  save() {
    const {
      company,
      _id
    } = this.employee
    const {
      name,
      email,
      address,
      warning,
      role,
      primaryInsurance,
      secondaryInsurance,
    } = this.state

    const { phone } = parseNumber(this.state.phone, 'US')

    const dob = unformatDate(this.state.dob)

    const update = {
      name,
      email,
      dob,
      phone,
      address,
      warning,
      role,
      company
    }

    this.props.patchEmployeeById(_id, update)

    this.setEditState(false)
  }

  delete() {
    const message = `Are you sure you want to delete this supervisor?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.employee._id
    this.props.deleteEmployeeById(id)

    console.log(this.employee._id)
  }

  renderContactInfo() {
    const {
      editing,
    //   phone,
    //   dob,
    //   name,
    //   email,
    //   role,
    //   dobDisplay
    //   role
    } = this.state
    const { employee } = this.props
    const {
      phone,
      dob,
      name,
      email,
      role,
      dobDisplay
      // role
    } = employee

    return (
      <div className={styles.contactInfo}>
          <div className="name">
            Name: 
            <Span
              editing={editing}
              type="text"
              value={this.state.name}
              placeholder={name}
              onChange={name => this.setState({ name })}
            >{name}</Span>
          </div>
          <div className="name">
            Email:
            <Span
              editing={editing}
              type="text"
              value={this.state.email}
              placeholder={email}
              onChange={email => this.setState({ email })}
              >{email}</Span>
          </div>

        <div>
          Date of Birth:
          {/* <Span
            editing={editing}
            type="date"
            value={dob}
            onChange={dob => this.setState({ dob })}
          >
          </Span> */}
            {dobDisplay || 'No date of birth set...'}
        </div>
        {/* <div>
          Address:
          <AddressLine
            editing={editing}
            state={this.state}
            address={address}
            onChange={newState => this.setState(newState)}
          />
        </div> */}
        <div>
          Phone Number:
          {/* <Span
            editing={editing}
            value={phone}
            placeholder="xxx xxx xxxx"
            onChange={this.handlePhoneChange.bind(this)}
          >
          </Span> */}
            {phone || 'No Phone...'}
        </div>
        <div className="role">
          {/* <Span
            type="text"
            label="Role"
            editing={editing}
            value={role}
            placeholder="role"
            onChange={role => this.setState({ role })}
          >
          </Span> */}
          Role:
            {role || 'None'}
          {/* <Span
            label="Role"
            type="text"
            editing={editing}
            value={role}
            onChange={role => this.setState({ role })}
          >
            {role || 'None'}
          </Span> */}
        </div>
      </div>
    )
  }

  renderSwitchTable() {
    const { tab } = this.state
    return (
      <SwitchTable
        tabs={this.tabOptions}
        selected={tab}
        onClick={tab => this.setState({ tab })}
      />
    )
  }

  renderNotesTab() {
    // return (
    //   <NotesTab
    //     className={styles.notesTab}
    //     state={this.state}
    //     employee={this.props.employee}
    //     setState={this.setState.bind(this)}
    //     onCreateNote={this.createNote.bind(this)}
    //     onCloseModal={() => this.closeModal()}
    //   />
    // )
  }

  render() {
    if (this.employee===null) {
      return null
    }

    const {
      editing,
      addressModal,
    } = this.state

    const {
      name
    } = this.employee

    return (
      <div>
        <Header className={styles.header}>
          <h2>
            {editing ? (
              <div>
                <Icon
                  cancel
                  onClick={() => this.setEditState(false)}
                />
                <Icon
                  save
                  onClick={() => this.save()}
                />
              </div>
            ) : (
              <div>
                <Icon
                  edit
                  onClick={() => this.setEditState(true)}
                />
                <Icon
                  cancel
                  onClick={() => this.delete()}
                />
              </div>
            )}
          </h2>
        </Header>
        <Body className={styles.body}>

          {this.renderContactInfo()}

          <div className="switch-buffer" />

          {this.renderSwitchTable()}
        </Body>

        {/* Address Modal */}
        {/* <AddressModal
          content={addressModal}
          onSubmit={address => this.setState({ address })}
          onClickAway={() => this.closeModal()}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, employees }) => {
  const {
    employeesDisplay,
    error,
  } = employees

  const {
    isAdmin,
  } = auth

  return {
    employee: employeesDisplay || {},
    error,
    isAdmin,
  }
}

const actions = {
  createEmployeeNote,
  patchEmployeeById,
  getEmployeeById,
  deleteEmployeeById,
}

export default connect(mapStateToProps, actions)(EmployeeView);
