import React, { Component } from "react";
import Display from "./Display/DisplayComponent";
import Keypad from "./Keypad/KeypadComponent";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() { 
    return (
      <div className="calculator">
        <Display
          displayInput={this.props.displayInput}
          displayResult={this.props.displayResult}
          deg={this.props.deg}
          theme={this.props.theme}
        />
  
        <Keypad
          toggleOperator={this.props.toggleOperator}
          operatorInUse={this.props.operatorInUse}
          handleClick={this.props.handleClick}
          handleBackSpace={this.props.handleBackSpace}
          handleClear={this.props.handleClear}
          handleDot={this.props.handleDot}
          handleSideDrawerKeys={this.props.handleSideDrawerKeys}
          handleRadorDeg={this.props.handleRadorDeg}
          deg={this.props.deg}
          inverse={this.props.inverse}
          toggleInverse={this.props.toggleInverse}
          sideDrawerOpen={this.props.sideDrawerOpen}
          toggleSideDrawer={this.props.toggleSideDrawer}
          theme={this.props.theme}
        />
      </div>
    );
  }
}
 
export default Main;
