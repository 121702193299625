// Router
import { push } from 'react-router-redux'

// Types
import { teamTypes, expensesTypes } from './types'

// Services
import { teams } from '../services'

//cookies
import * as cookies from 'js-cookie';

const {
  CREATE_TEAM_MEMBER,
  CREATE_TEAM_MEMBER_FAILURE,
  CREATE_TEAM_MEMBER_SUCCESS,
  GET_TEAMS,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  SET_TEAM_MEMBER,
  GET_TEAM_MEMBER,
  GET_TEAM_MEMBER_FAILURE,
  GET_TEAM_MEMBER_SUCCESS,
  PATCH_TEAM_MEMBER,
  PATCH_TEAM_MEMBER_FAILURE,
  PATCH_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM,
  DELETE_TEAM_FAILURE,
  DELETE_TEAM_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = teamTypes

const {
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_FAILURE,
} = expensesTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;

export const createTeamMember = payload => (dispatch, getState) => {
  dispatch({
    type: CREATE_TEAM_MEMBER,
  })
  teams.post(payload)
    .then(res => onCreateTeamMemberSuccess({dispatch, getState, res}))
    .catch(err => onCreateTeamMemberFailure({dispatch, err}))
}

function onCreateTeamMemberSuccess({dispatch, getState, res}) {
  const { admins } = getState().main
  dispatch({
    type: CREATE_TEAM_MEMBER_SUCCESS,
    payload: [...admins, res.user],
  })
  dispatch(push('/team'))
}

function onCreateTeamMemberFailure({dispatch, err}) {
  const payload = err.message || 'CreateTeamMember Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_TEAM_MEMBER_FAILURE,
    payload,
  })
}

export const setMember = payload => dispatch => {
  const { expenses } = payload
  dispatch({
    type: SET_TEAM_MEMBER,
    payload,
  })

  dispatch({
    type: GET_EXPENSES_SUCCESS,
    payload: expenses
  })
}

export const getTeams = () => dispatch => {
  dispatch({
    type: GET_TEAMS,
  })
  teams.getAll(cid,uid)
    .then(res => onGetTeamsSuccess({dispatch, res}))
    .catch(err => onGetTeamsFailure({dispatch, err}))
}

function onGetTeamsSuccess({dispatch, res}) {
  console.log('getTeam success');
  dispatch({
    type: GET_TEAMS_SUCCESS,
    payload: res.data,
  })
}

function onGetTeamsFailure({dispatch, err}) {
  console.log('getTeam failure', err);
  const payload = err.message || 'GetTeam Failed'
  window.alert(payload)
  dispatch({
    type: GET_TEAMS_FAILURE,
    payload,
  })
  dispatch(push('/teams'))
}

export const getMemberById = userId => (dispatch, getState) => {
  // check if a user has been set yet
  const { user } = getState().team
  if (user && user.id !== userId) {
    // clear it if it is not the same one
    console.log('clearing state');
    dispatch({
      type: CLEAR_STATE,
    })
  }

  dispatch({
    type: GET_TEAM_MEMBER,
  })
  teams.getById(userId)
    .then(res => onGetMemberSuccess({dispatch, res}))
    .catch(err => onGetMemberFailure({dispatch, err}))
}

function onGetMemberSuccess({dispatch, res}) {
  dispatch({
    type: GET_TEAM_MEMBER_SUCCESS,
    payload: res.data
  })
}

function onGetMemberFailure({dispatch, err}) {
  const payload = err.message || 'GetMember Failed'
  window.alert(payload)
  dispatch({
    type: GET_TEAM_MEMBER_FAILURE,
    payload
  })
  dispatch(push('/team'))
}

export const patchMemberById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_TEAM_MEMBER,
  })
  teams.patchById(id, update)
    .then(res => onPatchMemberSuccess({dispatch, res}))
    .catch(err => onPatchMemberFailure({dispatch, err}))
}

function onPatchMemberSuccess({dispatch, res}) {
  dispatch({
    type: PATCH_TEAM_MEMBER_SUCCESS,
    payload: res.user,
  })
}

function onPatchMemberFailure({dispatch, err}) {
  const payload = err.message || 'PatchMember Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_TEAM_MEMBER_FAILURE,
    payload,
  })
}

export const deleteTeamById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_TEAM,
  })
  teams.deleteById(id)
    .then(res => onDeleteTeamSuccess({dispatch, getState}))
    .catch(err => onDeleteTeamFailure({dispatch, err}))
}

// TODO: REFACTOR - HOT RELOAD
function onDeleteTeamSuccess({dispatch, getState}) {
  const team = getState().teams
  // const teams = team.filter(el => el.id !== team.id)

  dispatch({
    type: DELETE_TEAM_SUCCESS,
    payload: team,
  })
}

function onDeleteTeamFailure({dispatch, err}) {
  const payload = err.message || 'DeleteTeam Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_TEAM_FAILURE,
    payload,
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  }
}
