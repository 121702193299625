import React, { Component } from 'react';
import { createTimesheet, getTimesheet } from '../../../../../actions/timesheets';
import { connect } from 'react-redux';
import { Button, Table } from '../../../../common';
import TimesheetRow from './TimesheetRow';
import * as cookies from 'js-cookie';


class Timesheets extends Component {
  constructor (props) {
    super (props)
    this.state = {
    } 
  }
  componentDidMount() {
    this.props.getTimesheet()
  }

  onClick(e) {
    e.preventDefault()
    // if (this.formIsInvalid) {
    //   return
    // }
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    // console.log(me)
    const data = {
      company: me.company,
      user: me.id,
      startDate: new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', // Optional, include seconds if needed
        hour12: true, // Use 12-hour format; set to false for 24-hour format
      }).format(new Date())
    }
    this.props.timesheet.push(data)
    this.props.createTimesheet(data)
  }

  renderTableHead() {
    const { onDelete, onRestore, timesheets } = this.props;

    return (
        <thead>
          <tr>
            <th>Start Date</th>
            <th>Time</th>
            <th>Payout</th>
            <th>Approval Status</th>
            <th>Notes</th>
            <th>Details</th>
          </tr>
        </thead>
    );
  }

  renderTableBody() {
    const { deleteTimesheet, restoreTimesheet, timesheet } = this.props;
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined;
    // let filteredProducts = props.props
    // .filter(
    //   (product) => {
    //     return product.searchType.indexOf(this.state.search) !== -1
    //   }
    // )
    if (!Array.isArray(timesheet)) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              <Button times onClick={this.onClick.bind(this)}>
                New Timesheet
              </Button>
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {timesheet.map(times => (
            <TimesheetRow key={times._id} times={times} isAdmin={me.role} /* onDelete={ deleteTimesheet } onRestore={ restoreTimesheet } *//>
          ))}
            <Button times onClick={this.onClick.bind(this)}>
              New Timesheet
            </Button>
        </tbody>
      )
    }
  }

  render() {
    const { deleteTimesheet, restoreTimesheet, timesheets } = this.props;

    return (
      <div>
        <h1>Timesheets</h1>
        <Table bordered striped hover>
          {/* onclick render empty timesheet obj*/}
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      </div>
    );
  }
}

const mapStateToProps = ({ timesheets }) => {
  const {
    timesheetDisplay
  } = timesheets

  return {
    timesheet: timesheetDisplay
  }
}

const actions = {
  createTimesheet,
  getTimesheet
}

export default connect(mapStateToProps, actions)(Timesheets);
