import React, { Component } from 'react';
import { connect } from 'react-redux'

// Components
import {
  Table,
} from '../../../../../../common'

import SuperRow from './SuperRow/SuperRow'

import styles from './SuperTable.css'

class SuperTable extends Component {
  renderTableHead() {
    const { isadmin } = this.props
    return (
      <thead>
        <tr>
          {isadmin && (
            <th />
          )}
          <th>
            Date
          </th>
          <th>
            Team
          </th>
          <th>
            Time
          </th>
          <th>
            Description
          </th>
          {!isadmin && (
            <th />
          )}
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const { times, projects } = this.props
    if (!times.times.length) {
      return (
        <tbody>
          <tr>
            <td colSpan="7" className="empty">
              No Times Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {times.times.map(time => (
            <SuperRow
              key={time._id}
              times={time}
              projects={projects}
            />
          ))}
        </tbody>
      )
    }
  }

  render() {
    return (
      <Table className={styles.table}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }
}

const mapStateToProps = ({ expense, auth }) => {
  const {
    isadmin,
  } = auth

  const {
    checkedExpenses,
    expensesDisplay,
  } = expense

  return {
    isadmin,
    expenses: expensesDisplay,
    checkedExpenses,
  }
}

export default connect(mapStateToProps, null)(SuperTable)
