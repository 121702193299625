export const LIST = 'list_timesheets';
export const GET = 'get_timesheet';

// timesheet
export const CREATE_TIMESHEET = 'create_timesheet'
export const CREATE_TIMESHEET_SUCCESS = 'create_timesheet_success'
export const CREATE_TIMESHEET_FAILURE = 'create_timesheet_failure'

export const SET_TIMESHEET = 'set_timesheet'

export const GET_TIMESHEET = 'get_timesheet'
export const GET_TIMESHEET_SUCCESS = 'get_timesheet_success'
export const GET_TIMESHEET_FAILURE = 'get_timesheet_failure'

export const GET_SHEET_BY_ID = 'get_sheet_by_id'
export const GET_SHEET_BY_ID_SUCCESS = 'get_sheet_by_id_success'
export const GET_SHEET_BY_ID_FAILURE = 'get_sheet_by_id_failure'

export const GET_ADMIN_SHEET = 'get_admin_sheet'
export const GET_ADMIN_SHEET_SUCCESS = 'get_admin_sheet_success'
export const GET_ADMIN_SHEET_FAILURE = 'get_admin_sheet_failure'

export const BULK_SUBMIT_TIMES = 'bulk_submit_time'
export const BULK_SUBMIT_TIMES_SUCCESS = 'bulk_submit_time_success'
export const BULK_SUBMIT_TIMES_FAILURE = 'bulk_submit_time_failure'

//current tracking time entry item
export const TIME_ENTRY_INPUT_PULL               = 'time_entry_input_pull'
export const TIME_ENTRY_INPUT_PULL_SUCCESS       = 'time_entry_input_success'
export const TIME_ENTRY_INPUT_PULL_FAILURE       = 'time_entry_input_failure'
export const TIME_ENTRY_INPUT_START              = 'time_entry_input_start'
export const TIME_ENTRY_INPUT_STOP               = 'time_entry_input_stop'
export const TIME_ENTRY_INPUT_STOP_SUCCESS       = 'time_entry_input_stop_success'
export const TIME_ENTRY_INPUT_STOP_FAILURE       = 'time_entry_input_stop_failure'
export const TIME_ENTRY_INPUT_CHANGE_START_TIME  = 'time_entry_input_change_start_time'
export const TIME_ENTRY_INPUT_CHANGE_START_TIME_SUCCESS = 'time_entry_input_change_start_time_success'
export const TIME_ENTRY_INPUT_CHANGE_START_TIME_FAILURE = 'time_entry_input_change_start_time_failure'
export const TIME_ENTRY_INPUT_CHANGE_TEXT        = 'time_entry_input_change_text'
export const TIME_ENTRY_INPUT_CHANGE_TEXT_SUCCESS = 'time_entry_input_change_text_success'
export const TIME_ENTRY_INPUT_CHANGE_TEXT_FAILURE = 'time_entry_input_change_text_failure'
export const TIME_ENTRY_INPUT_REMOVE             = 'time_entry_input_remove'
export const TIME_ENTRY_INPUT_REMOVE_SUCCESS     = 'time_entry_input_remove_success'
export const TIME_ENTRY_INPUT_REMOVE_FAILURE     = 'time_entry_input_remove_failure'
export const TIME_ENTRY_INPUT_ASSIGN_TAG         = 'time_entry_input_assign_tag'
export const TIME_ENTRY_INPUT_ASSIGN_TAG_ID      = 'time_entry_input_assign_tag_id'

//time entries
export const TIME_ENTRIES_FETCH_LIST  = 'time_entries_fetch_list'
export const FETCH_LIST_SUCCESS  = 'fetch_list_success'
export const FETCH_LIST_FAILURE  = 'fetch_list_failure'
export const TIME_ENTRIES_REMOVE      = 'time_entries_remove'
export const TIME_ENTRIES_ASSIGN_TAG = 'time_entries_assign_tag'
export const TIME_ENTRIES_ASSIGN_TAG_SUCCESS = 'entries_assign_tag_success'
export const TIME_ENTRIES_ASSIGN_TAG_FAILURE = 'entries_assign_tag_failure'
export const TIME_ENTRIES_ASSIGN_STATUS = 'time_entries_assign_status'
export const TIME_ENTRIES_ASSIGN_STATUS_SUCCESS = 'time_entries_assign_status_success'
export const TIME_ENTRIES_ASSIGN_STATUS_FAILURE = 'time_entries_assign_status_failure'
export const ASSIGN_TAG_SUCCESS = 'assign_tag'
export const ASSIGN_TAG_FAILURE = 'assign_tag'

//report
export const REPORT_FETCH = 'report_fetch'

//tags
export const FETCH_LIST = 'fetch_list'