import { api } from '../config'

export default {
  post(data) {
    const url = '/time'
    return api.post(url, data)
  },

  getAll(cid,uid) {
    const url = `${cid}/time/${uid}`
    return api.get(url)
  },

  getAdmin(cid) {
    const url = `/site/${cid}`
    return api.get(url)
  },

  getById(id) {
    const url = `/time/${id}`
    return api.get(url)
  },

  timeStart(id, data) { 
    const url = `/time/${id}`
    return api.post(url, data)
  },

  timeStop(id, data) { 
    const url = `/time/${id}`
    return api.post(url, data)
  },

  updateText(id, data) { 
    const url = `/time/${id}`
    return api.patch(url, data)
  },

  updateTime(id, data) { 
    const url = `/time/${id}`
    return api.patch(url, data)
  },

  submit(id, data) {
    const url = `/time/submit/${id}`
    return api.post(url, data)
  },

  deleteById(id) {
    const url = `/time/${id}`
    return api.delete(url, id)
  }
}
