/* eslint-disable no-undef */
/* eslint-disable no-new-object */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { 
  Button, 
  FormModal, 
  Input, 
  Selector, 
  Span, 
  TextArea
} from '../../common';
import {
  formatCurrencyOptions
} from '../../../lib';
import styles from './timerModal.css';
import { getTimeEntry, getTimesheet, start, stop, updateTime, updateTimesheet } from '../../../actions/timesheets';
import Timer from '../Timer/Timer';

class TimerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      timer: 0,
      notes: '',
      user: null,
      projects: null,
      timesheet: null,
      description: ''
    }
  }

  get options() {
    const { projects } = this.props;
    let base = [
      {
        value: '',
        display: 'Select Project or Team',
      }
    ];
    projects.map(project => {
      const p = {
        value: project._id,
        display: project.name
      }
      base.push(p)
    })
    return base;
  };

  get inactive() {
    const { type } = this.state
    return !type
  }

  componentDidMount() {
  }

  submit(e) {
    const {
      type,
      timer,
      notes,
      user,
      projects,
      timesheet,
      description
    } = this.state
    const {
      me
    } = this.props
    let startDate = new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(new Date())  
    this.props.stop(me.id, description, startDate, e, type, JSON.parse(sessionStorage.getItem('select_timesheet'))._id)
    this.props.onClickAway()
  }

  renderTimer() {
    const { description, type } = this.state;
    return (
      <div>
        <TextArea
          placeholder="What are you working on?"
          value={description}
          onChange={description => this.setState({ description })} />
        <Timer type={type} me={this.props.me} submit={this.submit.bind(this)}/>
      </div>
    )
  }

  renderReceipt() {
    const {
      receipt,
      onClickAway
    } = this.props
    const {
      account,
      details,
      items,
      description,
      amount,
    } = receipt
    const price = formatCurrencyOptions(amount)
    return (
      <div>
        <h3>{account}</h3>
        <p>{description}</p>
        <Span>{items}</Span>
        <Span>{details}</Span>
        <h4>{price}</h4>
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Close"
            onClick={onClickAway}
          />
        </div>
      </div>
    )
  }

  renderOptions() {
    const {
      type
    } = this.state
    const {
      receipt
    } = this.props
    return (
      <div>
        <Selector
          wide
          value={this.state.type}
          onSelect={i => this.setState({ type: i })}
          options={this.options}
        />

        {this.renderTimer()}
        {!!receipt && this.renderReceipt()}
      </div>
    )
  }

  renderFundingForm() {
    const {
      onClickAway
    } = this.props
    const {
      amount
    } = this.state
    // const funds = formatCurrencyOptions(toString(amount))
    // console.log(funds)
    return (
      <div>
        <br />
        <label>
          Amount
        </label>
        <Input
          placeholder="123"
          type="number"
          value={amount}
          onChange={amount => this.setState({ amount })}
        />
        <br />
        <p>+6% Gas</p>
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Add Funds"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div>
    )
  }

  render() {
    const { onClickAway, content } = this.props
    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
      >
        {this.renderOptions()}
      </FormModal>
    )
  }
}

const mapStateToProps = ({ timesheets }) => {
  const {
    timesheetDisplay
  } = timesheets

  return {
    timesheet: timesheetDisplay
  }
}

const actions = {
  getTimeEntry,
  getTimesheet
}

export default connect(mapStateToProps, actions)(TimerModal);
